const mockTabletsProducts = [
  { 
    idOito: 1, 
    nameOito: 'PASTILHA ADESIVA SANY LAVANDA 3UN', 
    imageUrlOito: 'https://64.media.tumblr.com/a44ec854b83da72a5a4b6381b7a43bda/b862eced1d4931e3-4a/s250x400/c0f0125b104d2fb7f92f0f7a2616426d20b8f46d.pnj' 
  },
  { 
    idOito: 2, 
    nameOito: 'PASTILHA CX ACOPLADA SANY 50G LAVANDA', 
    imageUrlOito: 'https://64.media.tumblr.com/d0b059b0d3692926a90939e5a4e100c1/b862eced1d4931e3-33/s250x400/dcd2ca16bd3502f3d97df995a614aa757e9b5b4f.pnj' 
  },
  { 
    idOito: 3, 
    nameOito: 'PEDRA SANITARIA KAROMA 40G MISTA', 
    imageUrlOito: 'https://64.media.tumblr.com/cae0cee673b380b5443acdad009c701b/b3153cc7713d7895-b8/s400x600/5c4badc871dba6cf0be6dcf0db9f23412c09415e.pnj' 
  },
  { 
    idOito: 4, 
    nameOito: 'BLOCO SANITARIO LIPEX CX ACOPL 50G', 
    imageUrlOito: 'https://64.media.tumblr.com/16b2fb9b43deedd348cb264e6cf787fd/b862eced1d4931e3-42/s250x400/2ab4649301b29ea6efea9a8af03bfad122df6e6a.pnj' 
  },
  { 
    idOito: 5, 
    nameOito: 'PASTA P LIMPEZA LIMPTEK POTE 500G', 
    imageUrlOito: 'https://64.media.tumblr.com/9d4994c2e6fe33cf52d15d7e95df0880/b862eced1d4931e3-be/s250x400/684b9c2a70ade3be103f5797996b3831f00dcaec.pnj' 
  },
  { 
    idOito:6, 
    nameOito: 'PASTA MULTIUSO CRISTAL ROSA 500G', 
    imageUrlOito: 'https://64.media.tumblr.com/f114df4ded5da23b0a99771e05efbdf4/b862eced1d4931e3-2e/s250x400/21d4b509337ebc51280e4a88b482dad9e023918d.pnj' 
  },
  { 
    idOito: 7, 
    nameOito: 'SABAO EM PASTA TRADICIONAL 500G', 
    imageUrlOito: 'https://64.media.tumblr.com/402a27f74cac66a5eb5d36cac4fb80cb/b862eced1d4931e3-a2/s250x400/4bda415e4071767e4bb1e36ef82c0fe31e8b032f.pnj' 
  },
  { 
    idOito: 8, 
    nameOito: 'SABAO PASTOSO UFE 500G COCO', 
    imageUrlOito: 'https://64.media.tumblr.com/38780bc1575a367adeac8c0e0f92b04a/b862eced1d4931e3-06/s250x400/774f02b75520e00dd368900d06e4c3730eb59fec.pnj' 
  },
]

export default mockTabletsProducts