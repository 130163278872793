import './App.scss';
import Article from './Components/Article/Article';
import Navbar from './Components/Navbar/Navbar';
import Section from './Components/Section/Section';
import Products from './Components/Products/Products';
import mockProducts from './Assets/mockProduct/mockProduct';
import Footer from './Components/Footer/Footer' 
import SliderProducts from './Components/SliderProducts/SliderProducts';

function App() {
  return (
    <div>
      <Navbar />
      <Article />
      <Section />
      <Products />
      <SliderProducts mockProducts={mockProducts} mockFloorProducts={[]} />
      <Footer />
    </div>
  );
}

export default App;
