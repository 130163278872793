const mockLampsProducts = [
  { 
    idDez: 1, 
    nameDez: 'BULBO 12W 6500K AVANT', 
    imageUrlDez: 'https://64.media.tumblr.com/a8cffbd3d64f27c757da0d6f3fb29fed/8c89060ce161d29d-d5/s250x400/216bd71d9b9a7dcb9e40ef28e258228ff1e3cb8e.pnj' 
  },
  { 
    idDez: 2, 
    nameDez: 'LAMP BULBO FILAMENTO E27', 
    imageUrlDez: 'https://64.media.tumblr.com/18e202cd714642ec0b2a48a0b537a00c/8c89060ce161d29d-bf/s250x400/6a51f26737cba25be128f5d48842251f762575af.pnj' 
  },
  { 
    idDez: 3, 
    nameDez: 'FLUOR DULUX D/E 26W /840 OSRAM', 
    imageUrlDez: 'https://64.media.tumblr.com/744cc6041f7cf3a9c9f9d6ea6d30e809/8c89060ce161d29d-80/s100x200/af5837700d76099b2bb7e43366cdf6b7fd075304.pnj' 
  },
  { 
    idDez: 4, 
    nameDez: 'FLUOR 26W 6400K EMPALUX 4P', 
    imageUrlDez: 'https://64.media.tumblr.com/f550e82c2bf81e56ad6ce033e1d76753/8c89060ce161d29d-f5/s250x400/b59c91eee6be4081fdaccd43f16bdc7473cb4ed8.pnj' 
  },
  { 
    idDez: 5, 
    nameDez: 'LAMP ELET 15W 127V 3U T3 864 E27 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/33670f2aeb73e9cc91b9952fa2812c64/8c89060ce161d29d-b0/s250x400/e4bf3ac351e3bd544c6573e2a0864b67d6144b95.pnj' 
  },
  { 
    idDez: 6, 
    nameDez: 'LAMP ELET 15W 127V 3U T3 864 E27 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/779d0ac20096175c64659d488a1f6181/8c89060ce161d29d-8d/s250x400/26c579d9ded01965c8a3ef75311846b00bf25903.pnj' 
  },
  { 
    idDez: 7, 
    nameDez: 'LAMP LED TUB 9W 6,5K GLASS BIV G13 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/0b4e96f17a115da520f1535b5a3abe2d/8c89060ce161d29d-09/s250x400/3d54584d1b40d16032c6c821f4fd3f2a3a3e3640.pnj' 
  },
  { 
    idDez: 8, 
    nameDez: 'LUM LED SOB LINEAR 18W 6,5K BIV KSX FIT', 
    imageUrlDez: 'https://64.media.tumblr.com/8fb06292f67599b2c3334f7e3523ca4a/8c89060ce161d29d-f9/s250x400/f1302ef9609b2c36933d999da6a90bcbd6f2202c.pnj' 
  },
  { 
    idDez: 9, 
    nameDez: 'LAMP LED BOLINHA 4,8W 6,5K BIV E27 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/8ca54b466111d674b86d1b85c83592e2/f4b5bdddda2b2282-d7/s250x400/99aec8c20d2859b5db174a35955f344f445aac15.pnj' 
  },
  { 
    idDez: 10, 
    nameDez: 'LAMP LED BULBO A 12W 3,0K BIV E27 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/96331dcad4972546e7b2d327e3ca69c3/f4b5bdddda2b2282-0b/s250x400/9e2cc2d31caa3cddb14a1eb7e6294c8a01fb6666.pnj' 
  },
  { 
    idDez: 11, 
    nameDez: 'LAMP LED GLOBE 20W 6,5K BIV E27 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/d936138c362815e1efaf05c2ccc839b7/f4b5bdddda2b2282-e0/s250x400/17df8d20a6c77a7953f760143814b75627ebbe94.pnj' 
  },
  { 
    idDez: 12, 
    nameDez: 'LUM EMERGENCIA 30 LEDS SUPER SLIM', 
    imageUrlDez: 'https://64.media.tumblr.com/c12d1f40e474f8652419d155e36fe299/b930c9cfafb15690-23/s250x400/56ba6b6ca2fb73ba51f57c481a8ce5aacdfd6e75.pnj' 
  },
  { 
    idDez: 13, 
    nameDez: 'LÂMPADA LED IND BULB T 100W E27/E40 6,5K KI', 
    imageUrlDez: 'https://64.media.tumblr.com/944e2bfa12b2a360e62c402bc5f9535e/f4b5bdddda2b2282-a9/s250x400/7cdc2c841c24535c2c73ea9a8787cb60a0ff7158.pnj' 
  },
  { 
    idDez: 14, 
    nameDez: 'LÂMPADA LED MR16 6,5W 2,7K BIV GU10 KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/289cffb4c63af530630a117f2cd08783/f4b5bdddda2b2282-60/s250x400/82533a1168b25f37dfff34fb67c61baf73b3e5a0.pnj' 
  },
  { 
    idDez: 15, 
    nameDez: 'LÂMPADA LED PAR16 4W 36 827 350LM BIV GU10', 
    imageUrlDez: 'https://64.media.tumblr.com/32732ecfbec11fa5a695bf9461320cde/f4b5bdddda2b2282-9c/s250x400/379aa9d04bcad66032ed24af77b8afb888827dd9.pnj' 
  },
  { 
    idDez: 16, 
    nameDez: 'LUM LED BKL EMB QD BR 18W 6K KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/3e75dc99d9d6bd8cffffaae4df9a7065/f4b5bdddda2b2282-be/s250x400/4afca8c27b89d5335ebffe359ab170242de29698.pnj' 
  },
  { 
    idDez: 17, 
    nameDez: 'DICROICA 4W GU10 2700K', 
    imageUrlDez: 'https://64.media.tumblr.com/1b93bb116dbb6b7cc678b68ae94c415c/f4b5bdddda2b2282-81/s250x400/640cda24dc2e673b7c72daae420ea18b8f7d5528.pnj' 
  },
  { 
    idDez: 18, 
    nameDez: 'LUM EMERG LEA150', 
    imageUrlDez: 'https://64.media.tumblr.com/dcf71237a56efea9cb03f14d450e86ab/f4b5bdddda2b2282-37/s250x400/f2d8cc9a9599440c20c55e187699e0e86a419dfd.pnj' 
  },
  { 
    idDez: 19, 
    nameDez: 'LUM LED AR111 10W BIV 2,7K KIAN', 
    imageUrlDez: 'https://64.media.tumblr.com/1981839c215a70e96cbe5eaa630d7495/f4b5bdddda2b2282-d0/s250x400/7dc4a6599bf67d6e0753a31304ba20a55a44e71b.pnj' 
  },
  { 
    idDez: 30, 
    nameDez: 'DRIVE LED 18- 24W0MA 25A RDWS 4-D99 WEG', 
    imageUrlDez: 'https://64.media.tumblr.com/7e48b39bb2c8364d9a1d41391b0a02b5/6d4bea107f4bbf41-dc/s250x400/749581f14f8dc1521a2ab20793293af059ffa057.pnj' 
  },
  {
    idDez: 31,
    nameDez: 'FOTOC BOCAL E27 C/TIMER F RANDOM EXATRO',
    imageUrlDez: 'https://64.media.tumblr.com/96a8783863c641c7787504048be5a324/6d4bea107f4bbf41-c1/s250x400/6f5abb88931b974cf93c447f5b3b278e0cf067a9.pnj'
  },
  {
    idDez: 32,
    nameDez: 'DISK DR4 30MA 25A RDW 4-D99 WEG99 WEG',
    imageUrlDez: 'https://64.media.tumblr.com/c520ac59c167255a3ab5236cc8abc255/6d4bea107f4bbf41-6e/s250x400/25ca3625b45f671855af441b6949ac0b0e614845.pnj'
  },
  {
    idDez: 33,
    nameDez: 'RELE FOTOE BIV C/B FIXA CURUJI QTNX',
    imageUrlDez: 'https://64.media.tumblr.com/097713c85b2c0c24f652e2bace0adb69/6d4bea107f4bbf41-22/s250x400/87e2e77041a21ee16173538af4f7189c862d8e57.pnj'
  },
  {
    idDez: 34,
    nameDez: 'SENSOR DE PRESENÇA 60 QUALITRONIX',
    imageUrlDez: 'https://64.media.tumblr.com/9b11838d3bf8de7a6dacf35bd120abe9/6d4bea107f4bbf41-0d/s250x400/3155010dac2ed267f663847f31ffa68d3de6cca2.pnj'
  },
  {
    idDez: 35,
    nameDez: 'QD PVC EMB DIN 6A8 S/B BR TAF',
    imageUrlDez: 'https://64.media.tumblr.com/03e791af2fd1b0354e0766848cf7da12/6d4bea107f4bbf41-78/s250x400/ae3e920c0faa95b180f73decbbb72b269fcd202a.pnj'
  },
  {
    idDez: 36,
    nameDez: 'QUADRO PVC EMB DIN 6A8 S/B BR TAF',
    imageUrlDez: 'https://64.media.tumblr.com/a2013ea1d00ade8223c66f4f142c42c3/6d4bea107f4bbf41-34/s250x400/5c17e5aeca1dffc601c82e6bcca57422c5225b7f.pnj'
  },
  {
    idDez: 37,
    nameDez: 'FOTOC BOCAL E27 C/TIMER F RANDOM EXATRO',
    imageUrlDez: 'https://64.media.tumblr.com/e162e9b083851ca6aa73603272352345/6d4bea107f4bbf41-33/s250x400/76463470fe800a46353706c773a4984522021d47.pnj'
  },
  {
    idDez: 38,
    nameDez: 'FITA ISOLANTE 20M X 19MM 33+NOVA 3M',
    imageUrlDez: 'https://64.media.tumblr.com/bf7186ae1ffea684f6ba5f050b1287f3/6d4bea107f4bbf41-2d/s400x600/b772647c6605371ebe6a3d01fe6aa8c46c343c65.pnj'
  },
  {
    idDez: 39,
    nameDez: 'BLOCO DE ILUMINACAO AUTONOMO BLA 400',
    imageUrlDez: 'https://64.media.tumblr.com/0abbf267027af9f7047dc26e80009ebf/6d4bea107f4bbf41-e7/s250x400/40cdd625f20fbca4c92593506b75c3ea62a2fab0.pnj'
  },
  {
    idDez: 40,
    nameDez: 'TUB LED 9W 6500K AVANT 60CM',
    imageUrlDez: 'https://64.media.tumblr.com/2b3795030d42a1671d1c8c75cd88efe8/6d4bea107f4bbf41-16/s250x400/fe6e1ae762903a62c0e57507f4b7c9abcf9e649e.pnj'
  },
  {
    idDez: 41,
    nameDez: 'SUPORTE 4X2 PIAL HORIZ',
    imageUrlDez: 'https://64.media.tumblr.com/5a1adfb2c838d9e7e3413fcde36fbab7/6d4bea107f4bbf41-cf/s250x400/d9e7ebb10c8f4b6956fbb5c86215437a0fb8a43f.pnj'
  },
  {
    idDez: 42,
    nameDez: 'FILTRO DE LINHA',
    imageUrlDez: 'https://64.media.tumblr.com/b175bc8565d498b1f30521d770a5084b/6d4bea107f4bbf41-a7/s400x600/8e11962e3b7e6fe11ed740f5774d280711a9ad3f.pnj'
  },
  {
    idDez: 43,
    nameDez: 'VENT COLUNA OSCIL 50CM 6P PT 127V PREM',
    imageUrlDez: 'https://64.media.tumblr.com/5b121321878d0ba58218fe8b1b2e13a8/6d4bea107f4bbf41-24/s250x400/36291af7d1d0edcef2c45e63ecce268f646af513.pnj'
  }
];

export default mockLampsProducts
