import React from 'react';
import "./CardProduct.scss";

interface CardProductProps {
  name: string;
  imageUrl: string;
}

const CardProduct: React.FC<CardProductProps> = ({ name, imageUrl }) => {
  return (
    <div className="card-product-container">
        <img
          className="card-product-container__img-product"
          src={imageUrl}
          alt={name}
        />
        <p className="card-product-container__name-product">{name}</p>
    </div>
  );
};

export default CardProduct;