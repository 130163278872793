const mockSacoDeLixoProducts = [
  {
    idTreze: 1, 
    nameTreze: 'SACO P/ LIXO 110L COMERCIAL TRANSPARENTE C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/f2ad4e5c13c9d7aa28dfbe1e45a8478f/b878a8bc953abdad-33/s250x400/3ac7bf023bad52c2ca5485d4afd9f066adacbea2.pnj' 
  },
  {
    idTreze: 2, 
    nameTreze: 'SACO P/ LIXO 200L PESADO PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/335b1af117428a85aa5c0b57bf4c964b/f4385b90f54d14ca-09/s250x400/e8d06db85df6d761bc0e5bbe38664ef72da7176c.pnj' 
  },
  {
    idTreze: 3, 
    nameTreze: 'SACO P/ LIXO 300L REFORÇADO TRANSPARENTE C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/3e140589f7063e89ab992c1ef478bea2/f4385b90f54d14ca-26/s250x400/44d06b9447a8410c3f7c4629257f264733b272db.pnj' 
  },
  {
    idTreze: 4, 
    nameTreze: 'SACO P/ LIXO 400L SUPER REFORCADO PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/7004ed895ccf899187244cf8ed3e1867/f4385b90f54d14ca-a4/s250x400/00652bc48f1ea4da7886521579b23d11b48f2211.pnj' 
  },
  {
    idTreze: 5, 
    nameTreze: 'SACO P/ LIXO 240L SUPER PESADO 117 X 125 X PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/97de25368b3a8fa13ec7ec3470319cf5/f4385b90f54d14ca-25/s250x400/97e2e210d856fc59607a0c5f563d61998436c6c1.pnj' 
  },
  {
    idTreze: 6, 
    nameTreze: 'SACO P/ LIXO 240L PESADO PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/e6208182be0ecc1fa6170f0bd3427406/f4385b90f54d14ca-c0/s250x400/9e0f8da63d7c29824f020210bc5d79b1d8f3b21b.pnj' 
  },
  {
    idTreze: 7, 
    nameTreze: 'SACO P/ LIXO 300 LITROS REFORCADO 0,7 PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/4a4bea1b047338328f5f74124615310f/f4385b90f54d14ca-87/s250x400/b1d2b52590c02a8953b97d210aff37a721a9eebc.pnj' 
  },
  {
    idTreze: 8, 
    nameTreze: 'SACO P/ LIXO 360L SUP. REFORCADO 125X140X0,8 PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/082a71584826711e00c3cb42983d9d9a/f4385b90f54d14ca-f8/s250x400/b1195dda371d43fef734508cbd5c423497360859.pnj' 
  },
  {
    idTreze: 9, 
    nameTreze: 'SACO P/ LIXO 40L COMERCIAL 0,3 PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/3d1a11f1486e2956200c97a722c8eb33/f4385b90f54d14ca-65/s250x400/1d17897ff211dc16289edb23a5de7e8dc4a872b7.pnj' 
  },
  {
    idTreze: 10, 
    nameTreze: 'SACO P/ LIXO 40L PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/c64ae5e4db9b1966de535fff7086d743/f4385b90f54d14ca-e2/s250x400/018402930a1c620fa643bc42d62f0e5797b75d41.pnj' 
  },
  {
    idTreze: 11, 
    nameTreze: 'SACO P/ LIXO 60L COMERCIAL 0,3 PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/5f99cd0346b5e866505bf334d94c0436/f4385b90f54d14ca-08/s250x400/29fa6a2241206bc44598911d1872b80bf30f3b2d.pnj' 
  },
  {
    idTreze: 12, 
    nameTreze: 'SACO P/LIXO 240L SUPERPESADO PRETO C/100', 
    imageUrlTreze: 'https://64.media.tumblr.com/cd925f478598dc3091a94cd54a89f2db/f4385b90f54d14ca-25/s250x400/e384cd13e091bcb3be021614d2f19f3b41d9bbde.pnj' 
  },
]

export default mockSacoDeLixoProducts
