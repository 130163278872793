import React, { useEffect, useState } from 'react';
import './Brooms.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import CardProduct from '../CardProduct/CardProduct';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface Product {
  idSeis: number;
  nameSeis: string;
  imageUrlSeis: string;
}

interface ShowCaseProps {
  mockSpongeProducts: Product[];
}

const Esponjas: React.FC<ShowCaseProps> = ({ mockSpongeProducts }) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para o termo de busca
  const productsPerView = 3;

  useEffect(() => {
    if (swiper) {
      swiper.slideToLoop(currentIndex);
    }
  }, [currentIndex, swiper]);

  const handleSwiper = (swiperInstance: any) => {
    setSwiper(swiperInstance);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mockSpongeProducts.length - productsPerView : prevIndex - productsPerView
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + productsPerView >= mockSpongeProducts.length ? 0 : prevIndex + productsPerView
    );
  };

  const filteredProducts = mockSpongeProducts.filter((product) =>
    product.nameSeis.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const visibleProducts = filteredProducts.slice(currentIndex, currentIndex + productsPerView);

  return (
    <div className="slider-product-container">
      <div id="SliderProducts" className="slider-product-container__section-title">
        Esponjas, estopas, palhas de aço, fibras e outros
        <div>
          <input
            className='input-search'
            type="text"
            placeholder="Buscar produtos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='border'>
          {filteredProducts.length === 0 ? (
            <div className="no-products-found">Produto não encontrado.</div>
          ) : (
            <Swiper
              onSwiper={handleSwiper}
              slidesPerView={3}
              loop={true}
              className="swiper-container"
              breakpoints={{
                350: {
                  slidesPerView: 1,
                },
                700: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                }
              }}
            >
              {visibleProducts.map((product) => (
                <SwiperSlide key={product.idSeis}>
                  <div className="testezinho">
                    <CardProduct
                      name={product.nameSeis}
                      imageUrl={product.imageUrlSeis}
                    />
                  </div>
                </SwiperSlide>
              ))}
              <div className='arrow-left-right'>
                <div className="swiper-button-prev" onClick={handlePrevClick}>
                  <FaArrowLeft className="custom-icon" />
                </div>
                <div className="swiper-button-next" onClick={handleNextClick}>
                  <FaArrowRight className="custom-icon" />
                </div>
              </div>
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Esponjas;
