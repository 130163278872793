import "./Article.scss";

const Article = () => {
  return (

    <article className="article-container">
      <div className="article-container__title-mobile">
        <p className="article-container__title">Na LimpEletro</p>
        <p className="article-container__subtitle">Você economiza! </p>
        <p className="article-container__text">Aqui você encontra produtos de limpeza e materiais elétricos de alta qualidade com o menor custo e prazo de entrega!</p>
        <button className="article-container__btn">Fale Conosco</button>
      </div>

      <div>
        <img
          className="article-container__hero-img"
          src="https://64.media.tumblr.com/f38467f3af13c5af8e7aeb065b1fbfbb/1a1858da0890adb1-97/s500x750/a07afd086cbb6502bb831e697b5748323e41b6c9.pnj" 
          alt="Imagem de Pessoas Limpando um Recinto"
        />
      </div>
    </article>
  );
};

export default Article;