const mockSoapProducts = [
  { idQuatro: 1, 
    nameQuatro: 'SABÃO EM PÓ SURF 1,6KG', 
    imageUrlQuatro: 'https://64.media.tumblr.com/bd672f0685146e1bb17487b463df2efb/ef1d6c888a929c84-88/s250x400/c21c5a685b2c4a5c003f1cd884e025122f86cec5.pnj' 
  },
  { idQuatro: 2, 
    nameQuatro: 'SABAO EM PÓ SURF 800G', 
    imageUrlQuatro: 'https://64.media.tumblr.com/ca1c272701b7687d7e910dbd8401a51c/ef1d6c888a929c84-6d/s250x400/1c9dff48b63416dc4ddcb847941be34a8638e22c.pnj' 
  },
  { idQuatro: 3, 
    nameQuatro: 'ESPONJA DE AÇO BOMBRIL C/8 60G', 
    imageUrlQuatro: 'https://64.media.tumblr.com/174efe920d11de7d904151e4a300b71b/ef1d6c888a929c84-b8/s250x400/c4e6536528e01952e3c364510983612e6d921cf7.pnj' 
  },
  { idQuatro: 4, 
    nameQuatro: 'DETERGENTE PÓ ESPUMIL FLORES S.PLAST', 
    imageUrlQuatro: 'https://64.media.tumblr.com/b2e8058eeb43e1692c9b5cc7f9fd6b81/ef1d6c888a929c84-48/s250x400/2a1cb7c14f4ceb014d6fc144f756c7e230f8c0a8.pnj' 
  },
  { idQuatro: 5, 
    nameQuatro: 'SABAO PO SURF 1,6KG COCO', 
    imageUrlQuatro: 'https://64.media.tumblr.com/7ffbb26f63f8b84e023eb85f062d3999/ef1d6c888a929c84-a4/s250x400/2bbcb43f072800277ff12556b60daa8468af430d.pnj' 
  },
  { idQuatro: 6, 
    nameQuatro: 'SABAO DE COCO RUTH CARE 90G L5 P4', 
    imageUrlQuatro: 'https://64.media.tumblr.com/0922f65ecca22ade6b7a258d34946a39/ef1d6c888a929c84-b6/s250x400/3be0dd29c6f25aab8c0851560c402140aa3437bd.pnj' 
  },
  { idQuatro: 7, 
    nameQuatro: 'SABONETE LUX', 
    imageUrlQuatro: 'https://64.media.tumblr.com/adcf94fbf5034ad5ae86e7b46ea3ea72/ef1d6c888a929c84-92/s250x400/150a94f1d286e0daf9ab728127e9b3de763d336b.pnj' 
  },
  { idQuatro: 8, 
    nameQuatro: 'SABAO COCO MINUANO 100G 5UN', 
    imageUrlQuatro: 'https://64.media.tumblr.com/88b7e39e4bb82d63adb08b027faf38a5/ef1d6c888a929c84-cd/s400x600/18c9fcc3143e2d20d330df45225907660220e806.pnj' 
  },
  { idQuatro: 9, 
    nameQuatro: 'SABONETE SIENE 85G LAVANDA', 
    imageUrlQuatro: 'https://64.media.tumblr.com/941fdf1361cb3d24aea7c979ff50f988/ef1d6c888a929c84-5b/s400x600/ab59a4c177c266748c7b65e72bd1ce56d0b17729.pnj' 
  },
  { idQuatro: 10, 
    nameQuatro: 'SABONETE FLOR DE YPE 85G FRESIA E PESSEGO', 
    imageUrlQuatro: 'https://64.media.tumblr.com/05159e82c861cbcfc0e497f686706e95/ef1d6c888a929c84-aa/s250x400/ed73c6b99dbe2f2d37d0f7bf888fec16a2a6c445.pnj' 
  },
]

export default mockSoapProducts