const mockBucketProducts = [
  { idCinco: 1, 
    nameCinco: 'CESTO FUNCIONAL AZUL - BRALIMPIA', 
    imageUrlCinco: 'https://64.media.tumblr.com/88b657e501c55e4c7a7f4b09dc299f44/bb4c2042104997ff-51/s400x600/c30bb72f4cd6b6b7921a1217aae46068f4d6eba0.pnj' 
  },
  { idCinco: 2, 
    nameCinco: 'BALDE PLASTICO 10LTS', 
    imageUrlCinco: 'https://64.media.tumblr.com/a0e4d27a2b479a2472521005f976b203/bb4c2042104997ff-11/s250x400/5798b4213969e8b30f1389ae5d45fe9c3c954489.pnj' 
  },
  { idCinco: 3, 
    nameCinco: 'LIXEIRA PAPELEIRA C/ SUPORTE DE FERRO 50LTS', 
    imageUrlCinco: 'https://64.media.tumblr.com/2bbad757522e287b6166b2aec3236999/bb4c2042104997ff-07/s250x400/705550adfbe63077ca43040878f5b59f4dcd6e3d.pnj' 
  },
  { idCinco: 4, 
    nameCinco: 'COLETORA 240L (MOD. AMERICANO) - NOBRE COLETORA DE 240L CONTEMAR AMERICANO', 
    imageUrlCinco: 'https://64.media.tumblr.com/6fdcbb56e3098788a79f0f11c9f0d4cf/bb4c2042104997ff-ce/s250x400/a0becaeea4c2daec6900cd342996976f66c1b43b.pnj' 
  },
  { idCinco: 5, 
    nameCinco: 'LIXEIRA BASCULANTE MOOD 20L', 
    imageUrlCinco: 'https://64.media.tumblr.com/9183d818a9242f76201206d6f47bddca/bb4c2042104997ff-0a/s250x400/82b317c5c13079db71bd80184a0e259fe1d25b8b.pnj' 
  },
  { idCinco: 6, 
    nameCinco: 'LIXEIRA BASCULANTE 20L CINZA TR - MOOD', 
    imageUrlCinco: 'https://64.media.tumblr.com/f08f9ee33de09ccf0e736de924a98c61/bb4c2042104997ff-48/s250x400/e05fbde8436edc2c3d1fa89cdbfac4b03db07e4f.pnj' 
  },
  { idCinco: 7, 
    nameCinco: 'LIXEIRA PLASTICA C/PEDAL JAGUAR 40L', 
    imageUrlCinco: 'https://64.media.tumblr.com/f27ac7d34bd93094a8f9640a937ce943/bb4c2042104997ff-87/s250x400/80dfc2de7da42174d28c5d63f89c83349bfaa734.pnj' 
  },
  { idCinco: 8, 
    nameCinco: 'LIXEIRA PLASTICA C/PEDAL 15L BRANCO - JAGUAR', 
    imageUrlCinco: 'https://64.media.tumblr.com/bec98d1693a6729395e7d6f02e1be9e5/bb4c2042104997ff-51/s250x400/b0059aa355ae7013fef8b5889e3a1242b82555db.pnj' 
  },
]

export default mockBucketProducts