const mockSpongeProducts = [
  { idSeis: 1, 
    nameSeis: 'ESPONJA BRILHEX SCHWANKE', 
    imageUrlSeis: 'https://64.media.tumblr.com/fa636663af96a5001fbdbf989a27e1ab/fe581490af110e16-d3/s400x600/2d1ed9b393b721606a211ff4bc4658d6e74d2a4c.pnj' 
  },
  { idSeis: 2, 
    nameSeis: 'LUVA MULTIUSO ESFREBOM G PARRRO 50LTS', 
    imageUrlSeis: 'https://64.media.tumblr.com/4298cc5d01746fb43225861162b618a9/fe581490af110e16-e2/s250x400/489b95add9880c5b01c88fcc6edccc88edc3689b.pnj' 
  },
  { idSeis: 3, 
    nameSeis: 'ESTOPA BRANCA EXTRA - PCT. 1KG', 
    imageUrlSeis: 'https://64.media.tumblr.com/4f2beea9ca2e0df2524432cbc57e419e/fe581490af110e16-5e/s250x400/5b0a0cc419d9a280ff041dbff0fea5b4a870505a.pnj' 
  },
  { idSeis: 4, 
    nameSeis: 'PALHA DE ACO N.1 RELUZ 22G', 
    imageUrlSeis: 'https://64.media.tumblr.com/7bbc8b07526c9f03b7d943ead935cf58/fe581490af110e16-f8/s250x400/4a7f7cd108f60553621dd16a51e0442b577594ac.pnj' 
  },
  { idSeis: 5, 
    nameSeis: 'PALHA DE ACO N.2 RELUZ 22G', 
    imageUrlSeis: 'https://64.media.tumblr.com/c0c6080ac8c7b9ce71e7868cce8dcf34/fe581490af110e16-98/s250x400/292ce177df62430110077dacaac4337d79231710.pnj' 
  },
  { idSeis: 6, 
    nameSeis: 'FIBRA BETTANIN 102X260MM GERALVERDE', 
    imageUrlSeis: 'https://64.media.tumblr.com/3fc98db2d52c620b6ee0c774a6455a76/fe581490af110e16-e8/s250x400/9cdff63ecab089d7b19e02f65f8f1fa6b8f7aae6.pnj' 
  },
  { idSeis: 7, 
    nameSeis: 'FIBRA P/LIMPEZA 102X260MM LEVE/BRANCO, - NOBRE', 
    imageUrlSeis: 'https://64.media.tumblr.com/fc53be40546c34532fac4483649fa6d6/fe581490af110e16-0e/s250x400/1cb845f6b206fd6cfd42ad810128a5515fe72de8.pnj' 
  },
  { idSeis: 8, 
    nameSeis: 'ESCOVA 1030', 
    imageUrlSeis: 'https://64.media.tumblr.com/108590b0a6f7d8be253e02966c3e377a/fe581490af110e16-5e/s400x600/b153ca7f236cb898c33763d0adcd8b35c6d33081.pnj' 
  },
]

export default mockSpongeProducts