import React, { useState } from 'react';
import axios from 'axios';
import './EmailField.scss';

const EmailField = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [number, setNumber] = useState('');

  async function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!name || !email || !message || !number) {
      alert('Preencha todos os campos!');
      return;
    }

    const templateParams = {
      name,
      email,
      message,
      number,
    };

    try {
      const response = await axios.post('https://send-email-limpeletro.vercel.app/send-email', templateParams);
      console.log('Email enviado!', response.data);
      setName('');
      setEmail('');
      setNumber('');
      setMessage('');
      alert('Email enviado com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar o email:', error);
      alert('Erro ao enviar o email.');
    }
  }

  return (
    <footer className="footer-email-container">
      <h1 className="footer-email-container__title">Nos envie um Email</h1>

      <form className="form" onSubmit={sendEmail}>
        <input
          className="footer-email-container__input-name"
          type="text"
          placeholder="Digite seu nome"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />

        <input
          className="footer-email-container__input-email"
          type="text"
          placeholder="Digite seu email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <input
          className="footer-email-container__input-email"
          type="text"
          placeholder="Digite seu número"
          onChange={(e) => setNumber(e.target.value)}
          value={number}
        />

        <textarea
          className="footer-email-container__textarea-products"
          placeholder="Digite a mensagem que deseja enviar..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />

        <button className="footer-email-container__button-send" type="submit">Enviar</button>
      </form>
    </footer>
  );
};

export default EmailField;
