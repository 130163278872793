const mockProducts = [
  {
    id: 1,
    name: "KIT CARRO MULTIFUNCIONAL BRALIMPI",
    imageUrl: "https://64.media.tumblr.com/9c83adec7b564c1bbb30a63506fa461b/b7d19c6b9f057e3d-fe/s250x400/9a165046e400d93cc9b53f885e243c4ff9a59afa.pnj"
  },
  {
    id: 2,
    name: "PLACA SINALPISO MOLHADO - BRALIMPIA",
    imageUrl: "https://64.media.tumblr.com/d3cd271ce2d9c3bcc7c41312d0389180/aaf97fb633c145b3-37/s250x400/e45340d426642def9eba714a06d85650892c66f5.pnj"
  },
  {
    id: 3,
    name: "LIMPA TUDO BRALIMPIA EURO AZUL",
    imageUrl: "https://64.media.tumblr.com/2a67f5c347b5f6e795ea1d593747526b/e2680aa567ba2eb2-ec/s400x600/b6e08223dea28648d4b7cb2a46503aaa391e75e3.pnj"
  },
  {
    id: 4,
    name: "CONJ MOPINHO CABO 1,20M CJ50AM AMARELO KT QTD. 1.00 KT",
    imageUrl: "https://64.media.tumblr.com/1ef8fb39b4fa5ebadece2b4e22f74e72/0945cfac863c64fb-8b/s250x400/f80f10facd476ebb59f3ed6d59eec14d5530cd2d.pnj"
  },
  {
    id: 5,
    name: "ESFREGÃO COMPLETO",
    imageUrl: "https://64.media.tumblr.com/a9a0216beb103045681b48e56afc6f42/abe5887a4da0fe91-70/s250x400/b58cfde7be6c73e7648df9c095d371dd5ae4011d.pnj"
  },
  {
    id: 6,
    name: "MOP ESFREGAO ALGODAO",
    imageUrl: "https://64.media.tumblr.com/2a69099c57ce8574da6e14818ac57468/a476fddbabad886a-ef/s250x400/07c3e29f7017ee56cf53ea60633f8f79d34f1974.pnj"
  },
  {
    id: 7,
    name: "MOP ROTATORIO CONJ. BALDE13L C/CESTO PLAST. GOEDERT",
    imageUrl: "https://64.media.tumblr.com/4cb0b703931b4c1a99fbad8ac6566a1e/c6f8281c7c028612-ac/s250x400/d7df5b77f6f1bed3ad1ee0fb5db7e241c21da9da.pnj"
  },
  {
    id: 8,
    name: "MOP DE TORCAO (KD8215) - NOBRE",
    imageUrl: "https://64.media.tumblr.com/197719fd7813cfec68f7f4c42e43746b/536c032fa9112f40-8f/s250x400/d8ed5faf8ec4bf338aab3af72eb6d7f2facb992e.pnj"
  },
  {
    id: 9,
    name: "ESPANADOR DE PENAS NATURAL N40 GRANDE - SHANGRILA",
    imageUrl: "https://64.media.tumblr.com/a90e7cd9ca58788ae4309c10db7ec4b5/111a03893e19e8ac-89/s250x400/77f9432d98487176a6aa5c745cd65867aa6513ca.pnj"
  },
  {
    id: 10,
    name: "ESPANADOR DE PENAS NATURAL N30 MEDIO - SHANGRILA",
    imageUrl: "https://64.media.tumblr.com/ef6b58510f4d63b5d3e3a37bf746ae9b/21bb7f81fe1eddea-89/s250x400/fa637d8c267ebe35169f006d1d7abe0cdc96d7f0.pnj"
  },
  {
    id: 11,
    name: "ESCOVA SANITARIA PINCEL",
    imageUrl: "https://64.media.tumblr.com/58895c459b43a438cacd2b02d5ebe880/97a7eaf8977fc342-c8/s400x600/a3bed8c18434bded65f820f894adc5c2740458a9.pnj"
  },
  {
    id: 13,
    name: "RODO ROSSI 40 MADEIRA - RODO MADEIRA 40CM MARY",
    imageUrl: "https://64.media.tumblr.com/7186b8f93de21582ab2e4fd0a830bcce/11a17477c8586fe2-88/s400x600/1e14e0007651f5971023c7172f69fbe9135aa695.pnj"
  },
  {
    id: 14,
    name: "ROBO PLÁSTICO RODO 60CM C/CABO PLASTICO RODO PLASTICO 60 CM ROSSI",
    imageUrl: "https://64.media.tumblr.com/f2871278680d1a637a309182ceb759d6/dce292ab99eec98e-51/s250x400/5708d4d60e272308dd1e0211bf4e6380e7e180f6.pnj"
  },
  {
    id: 15,
    name: "LIMPA AZULEJO",
    imageUrl: "https://64.media.tumblr.com/de7600b6bc72bedc4000774f4e03065d/83e3be7f2c691f62-e6/s250x400/7809f61e223e3f9196870acc26d93af26bb8cc4e.pnj"
  },
  {
    id: 16,
    name: "VASSOURA PELO SINTETICO 60CM CABO PLAST",
    imageUrl: "https://64.media.tumblr.com/5a5eb35b7f8f1264333b6f79c539161f/983e1e2efc71bbe5-21/s250x400/9d3848bbdaa724061328c8fbba660e23aa8639fa.pnj"
  },
  {
    id: 17,
    name: "VASSOURA NOVICA PLASTICA CB PLASTIFICADO",
    imageUrl: "https://64.media.tumblr.com/cd54d8d01ed801b8cea152b1cb123e69/983e1e2efc71bbe5-46/s250x400/8f27239d8ca78758ff444f47612429c5705db1e3.pnj"
  },
  {
    id: 18,
    name: "VASSOURA PELO 40CM",
    imageUrl: "https://64.media.tumblr.com/2092244497e718cf5265cceb2851178f/983e1e2efc71bbe5-95/s250x400/48a07f09fe20b5481e9862e1d90fb9cbdfc6a742.pnj"
  },
  {
    id: 19,
    name: "VASSOURA RETA 40CM S/ CABO BETTANIN",
    imageUrl: "https://64.media.tumblr.com/97d801ddc7eff3189babee4b78bd34fd/983e1e2efc71bbe5-65/s400x600/ce8e83fb32dfddbccc9e1ecb5cb1983c5e3b634f.pnj"
  },
  {
    id: 20,
    name: "VASSOURA CERDA MACIA PLAST 60CM BETTANIN SEM CABO",
    imageUrl: "https://64.media.tumblr.com/bfc807b158622afe17cb1ce404f9f213/983e1e2efc71bbe5-9b/s400x600/64a932b8aebbf28d9bf5aff50ef40f5ef1ff3102.pnj"
  },
  {
    id: 21,
    name: "VASSOURA SANTA MARIA PHENIX",
    imageUrl: "https://64.media.tumblr.com/cd14d4a3865d07e940082a63b098c548/983e1e2efc71bbe5-3b/s250x400/7e26b6a26234e085bae8a36366643643cb656329.pnj"
  },
  {
    id: 22,
    name: "VASSOURA PIAÇAVA CHAPA 06 ROSSI",
    imageUrl: "https://64.media.tumblr.com/2f4a21f8398cebd261777a0dcb46537e/983e1e2efc71bbe5-9b/s250x400/26693b145095ba5ec5497f6f10d15c9be47183ac.pnj"
  },
  {
    id: 23,
    name: "VASSOURA PIAÇAVA MARY 5",
    imageUrl: "https://64.media.tumblr.com/ca0dec95b659cb9adccbe5ba11306ef1/983e1e2efc71bbe5-cd/s250x400/288ff445027c475dbe5cf7a4ba992c8dc7af593b.pnj"
  },
  {
    id: 24,
    name: "ESFREGÃO DE NYLON PLASTICO",
    imageUrl: "https://64.media.tumblr.com/4ae39e2bdda36bbd09a78d4abcd5ad65/d4a4bf9dceedb021-76/s250x400/cadc61151f613cf0ac461240e7e2ed10320797c6.pnj"
  },
  {
    id: 25,
    name: "VASSOURA DE CHAPA MILAGRE C6(017) AM 001",
    imageUrl: "https://64.media.tumblr.com/91847418ea246cee3d13db50e52ed2eb/d4a4bf9dceedb021-30/s250x400/90877766011ddd64e0ac306d1c0349c73b26993c.pnj"
  },
  {
    id: 26,
    name: "VASSOURA GARY ROSSI C36 FUROS VASSOURA GARI 36 FUROS FACA CB 1, 50 MTS VASSOURA GARI 36 FUROS MARY",
    imageUrl: "https://64.media.tumblr.com/72b87bbcd8d1b946a8fcf27f3fff10bb/d4a4bf9dceedb021-5a/s400x600/804c09286627adf2eb66e4b8b4489fd1a469c818.pnj"
  },
  {
    id: 27,
    name: "ESFREGAO PIAÇAVA",
    imageUrl: "https://64.media.tumblr.com/5b4571dbcc10e03df1784d4027e55243/d4a4bf9dceedb021-c4/s400x600/94c9950063f760134ed50a185f3583a1552b90c0.pnj"
  },
  {
    id: 28,
    name: "VASSOURA LIMPA TETO C CABOS A PARTE C 12(586) CX 001",
    imageUrl: "https://64.media.tumblr.com/05f9a91afa50e65cc8cbda5dd1e8b876/d4a4bf9dceedb021-67/s250x400/0ee98712feedb83a50927186254fc38037bb3fcc.pnj"
  },
  {
    id: 29,
    name: "VASCULHO DE NYLON CABO 1, 20",
    imageUrl: "https://64.media.tumblr.com/262d104fe0570ca4ec24d2fadc174883/d4a4bf9dceedb021-37/s250x400/c2dc1fd09044f5ebe6faea4cf0bd34bf50fc2af2.pnj"
  },
  {
    id: 30,
    name: "DESENTUPIDOR DE SANITARIO",
    imageUrl: "https://64.media.tumblr.com/7136cabdcd4e6c61d3f0c402cb06f35f/c00f42cf8ef8d32d-f2/s250x400/2cd3402c7277b14f1c9ce2d27e5ea3148b7911e9.pnj"
  },
  {
    id: 31,
    name: "DESENTUPIDOR PIA SANFONADO",
    imageUrl: "https://64.media.tumblr.com/ff942d0f616509df34325e8f0cc1dcb8/c00f42cf8ef8d32d-1f/s250x400/b608af3a61071d3bafd57e1090209929f9983ce5.pnj"
  },
  {
    id: 32,
    name: "ESCOVA SANITARIA C/ SUPORTE",
    imageUrl: "https://64.media.tumblr.com/e54f03035eacb6f57cf5c8de271a4e9d/c00f42cf8ef8d32d-75/s250x400/201178d97afd31f80ca08b31bd54a099b980eab8.pnj"
  },
  {
    id: 33,
    name: "PULVERIZADOR PROFISSIONAL 1L BRALIMPIA",
    imageUrl: "https://64.media.tumblr.com/8077e35e8ccf2b6211229d96c9758436/c00f42cf8ef8d32d-3f/s100x200/dac872b463fc57602e4a4cdb191993e5ae388ca1.pnj"
  },
  {
    id: 34,
    name: "PA COLETORA C/ CABO BETTANIN",
    imageUrl: "https://64.media.tumblr.com/c889dec2f145654fb67fc1be8154a81a/c00f42cf8ef8d32d-c7/s250x400/d325c4bf8472e804c36687eec4ecb8c935998a67.pnj"
  },
  {
    id: 35,
    name: "PA DE LIXO GALVANIZADA",
    imageUrl: "https://64.media.tumblr.com/c45106e5bbb323ed225d8740290da7dc/c00f42cf8ef8d32d-29/s250x400/b72d76fabedcca72b682167a601c395b0cbc4c1e.pnj"
  },
  {
    id: 36,
    name: "PA PLASTICA PARA LIXO CABO ROSSI",
    imageUrl: "https://64.media.tumblr.com/0ab97016e57cf10fe56fa288c652d0fd/c00f42cf8ef8d32d-12/s250x400/6c215e9fa926d90ac6b7e0cced0bf495be0b1730.pnj"
  },
  {
    id: 37,
    name: "PA COLETORA S/ TAMPA AZUL BRALIMPIA",
    imageUrl: "https://64.media.tumblr.com/a6a9e41d4afe95cba92ff59e3beabf67/c00f42cf8ef8d32d-df/s250x400/79dde6a01ff72d95fb6b8456b574e3909aa3c75f.pnj"
  },
  {
    id: 38,
    name: "RODO PLÁSTICO 60CM",
    imageUrl: "https://64.media.tumblr.com/a69088f5cd2dae2cbcf324a515290663/4e8164e6d7ef86e1-a6/s400x600/21f5714d3a9f2f9f46c0b2e4ce2faa789db371d7.pnj"
  },
  {
    id: 39,
    name: "RODO PLÁSTICO 40CM",
    imageUrl: "https://64.media.tumblr.com/e48772b8d693b5a5ed4d104e2a75b6a2/4e8164e6d7ef86e1-7f/s250x400/d23c200664017b3ac1a379acd7ae3839b4eb1ee9.pnj"
  },
];

export default mockProducts;


