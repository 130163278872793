const mockGlovesProducts = [
  { 
    idNove: 1, 
    nameNove: 'LUVA SEG COTTON BR TAM U CA30521 VOLK', 
    imageUrlNove: 'https://64.media.tumblr.com/7026d4a806da1d27a48a68a8fb660a08/bb1875423c29e5f4-0c/s250x400/57abd2091666d7c4240b770a8a0c4f870e5c4c46.pnj' 
  },
  { 
    idNove: 2, 
    nameNove: 'LUVA LATEX SL BIC TAM G CA37900 VOLK', 
    imageUrlNove: 'https://64.media.tumblr.com/bc4383bce65eb28f5ad7663f78d2a71b/bb1875423c29e5f4-85/s250x400/3c97a19fa07c721a2138c179facecb63c4201868.pnj' 
  },
  { 
    idNove: 3, 
    nameNove: 'LUVA DE BORRACHA/LATEX FORRADA “ G“ 280 AMARELA SOFT', 
    imageUrlNove: 'https://64.media.tumblr.com/92b9a4d8b235ca27be820998f91c34fa/bb1875423c29e5f4-c1/s250x400/7c7b037142c43bf7be0b4ba304beb24a2322580d.pnj' 
  },
  { 
    idNove: 4, 
    nameNove: 'LUVA MAXI LATEX', 
    imageUrlNove: 'https://64.media.tumblr.com/1e72618b60799c22f55ffa4d50522f57/bb1875423c29e5f4-2f/s250x400/c8fe6aae929865f9460d238535b6abede9f053f1.pnj' 
  },
  { 
    idNove: 5, 
    nameNove: 'SUPORTE PLASTICO S/CABO C/PINCA P/FIBRAS (MINILOOK)', 
    imageUrlNove: 'https://64.media.tumblr.com/6de1ebf06d4f2e55ccd1cca51bafcd16/bb1875423c29e5f4-9e/s250x400/4b5b748af5da83483f3053b4373b11caf1925fc4.pnj' 
  },
  { 
    idNove: 6, 
    nameNove: 'SUPORTE LIMPA TUDO', 
    imageUrlNove: 'https://64.media.tumblr.com/611bd67ff581362c95d93bb7ff62de55/bb1875423c29e5f4-90/s250x400/0e36ece54757bd03fda9fc5b5f66f138a54a87a5.pnj' 
  },
  { 
    idNove: 7, 
    nameNove: 'CABO ACO 150CM C/ROSCA BETANIN', 
    imageUrlNove: 'https://64.media.tumblr.com/8e05802a2b4c839f8397a741e45a7e03/bb1875423c29e5f4-03/s250x400/88e29aa24a03779f746b6ee78f3130e5f74b9391.pnj' 
  },
  { 
    idNove: 8, 
    nameNove: 'CABO ALUMINIO CABO ALUMINIO ESTRIADO 140CM CABO DE ALUMINIO 22MMX1,40M. NATURAL - NOBRE', 
    imageUrlNove: 'https://64.media.tumblr.com/093d97cd277bb749787fa5f6d734549a/bb1875423c29e5f4-98/s100x200/d6eb89b2ab2c79a21e2b3b5805264b883e6fe991.pnj' 
  },
]

export default mockGlovesProducts