const mockFloorProducts = [
  { idDois: 1, 
    nameDois: 'AUDAX MAX DESINFETANTE LAVANDA 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/af635f064e154613f9e7c27ed2c98b25/0857e8ae733b7139-00/s250x400/be9e43e044612797a3f4baf72de1abcaf8825f0b.pnj' 
  },
  { idDois: 2, 
    nameDois: 'CLORO CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/259fb64c4470df56668f9e14ad681d63/0857e8ae733b7139-bb/s250x400/c9a6fa0e81d0b5f81902794f2cfd219a1e413b91.pnj' 
  },
  { idDois: 3, 
    nameDois: 'CERA PRETA CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/bf0686ef02407ac308cb4681b6741617/0857e8ae733b7139-8d/s250x400/d5440d8ab1cea7000207d5ba3e8174fc3a7f6e98.pnj' 
  },
  { idDois: 4, 
    nameDois: 'AGUA SANITARIA TOPBEL 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/148d1f711afeef93c2bb3d4ab73e2585/0857e8ae733b7139-fd/s250x400/21f30011d472dbcdf328a015a0af677322b8df2d.pnj' 
  },
  { idDois: 6, 
    nameDois: 'AGUA SANITARIA CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/2daa271179e772cac45a7b1722ee7abe/0857e8ae733b7139-a3/s250x400/3b432a08c16bfe181b5dc920aa4fa283c40613c4.pnj' 
  },
  { idDois: 7, 
    nameDois: 'CLORO GEL 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/1cfd5082c1855f1ae928aaaf982d328f/0857e8ae733b7139-1a/s250x400/882238f2557768dc272e0c8d97a5e0bc301d6839.pnj' 
  },
  { idDois: 8, 
    nameDois: 'DESINFETANTE BRIOSOL JASMIN 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/ba7eb3c039f8f8a5814cc67ca1ef1503/0857e8ae733b7139-40/s250x400/1c6541d07b5b7c014058f969dcb8aa04abe783a6.pnj' 
  },
  { idDois: 9, 
    nameDois: 'DESINFETANTE EUCALIPTO CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/da6e4bc8b730a031898866991690d9a8/0857e8ae733b7139-86/s250x400/a0092da25670feab35dafcdb72cce8c52fdd993e.pnj' 
  },
  { idDois: 10, 
    nameDois: 'DESINFETANTE BRIOSOL EUCALIPTO 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/ec9db05b91b301733cca9fba50fcff20/0857e8ae733b7139-3f/s250x400/71b9ec74088d2df3f738c85744fc897a780dc6b7.pnj' 
  },
  { idDois: 11, 
    nameDois: 'DETERGENTE CONCENTRADO CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/c3bbb5b6ee8e1c2f4e805298ed2fcf31/0857e8ae733b7139-9c/s250x400/9d8650153e76bc896439a0ec399d5afd9f68419f.pnj' 
  },
  { idDois: 12, 
    nameDois: 'DETERGENTE P/ LOUÇA THAMES 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/84881d50dbe394574de763ba770e50b0/0857e8ae733b7139-39/s250x400/92baa02eaddb47e98f6315a9351fff227b6a44dd.pnj' 
  },
  { idDois: 13, 
    nameDois: 'DEXMAX DESINFETANTE CONC. LAVANDA 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/80019003d5aa3acde76119718f9ed178/0857e8ae733b7139-85/s250x400/afe82c385f8bff68aaec202d4f1e230da0f5dfe2.pnj' 
  },
  { idDois: 14, 
    nameDois: 'KLIO FLOR RENKO', 
    imageUrlDois: 'https://64.media.tumblr.com/257eb3ea8de2387456c55a7a8f1ddb52/0857e8ae733b7139-34/s250x400/589e9072e9be79da40d67e4a1b7ff344cd3929b2.pnj' 
  },
  { idDois: 15, 
    nameDois: 'LIMPA PEDRAS AUDAX 5LT', 
    imageUrlDois: 'https://64.media.tumblr.com/48f52edd0020e3b92cb7c93fd0f604df/0857e8ae733b7139-e0/s250x400/79bb2bfb01044af6ad8d96afc2ba7c3dc0e934cd.pnj' 
  },
  { idDois: 16, 
    nameDois: 'LIMPA PISOS START 5L PRONTO USO', 
    imageUrlDois: 'https://64.media.tumblr.com/34d1787b4ac84aa4d38e09bb173fc965/0857e8ae733b7139-e9/s250x400/5b7a50873beb25e06a57c9700101906895441ba0.pnj' 
  },
  { idDois: 17, 
    nameDois: 'LIMPADOR NEUTRO 5 LTS - THAMES', 
    imageUrlDois: 'https://64.media.tumblr.com/972c0396578649b039dce712aef5a84c/f384e1e0a2c8bee9-32/s250x400/273bc89e064c8cfff33f77d7630cc9452b20e680.pnj' 
  },
  { idDois: 18, 
    nameDois: 'LIMPADOR PERFUMADO LAVANDA SOFT CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/ffd1e81f5ac1215098fa3bb480c51581/f384e1e0a2c8bee9-59/s250x400/2bdbe457bf04fc1008d2394a52656787c635fc21.pnj' 
  },
  { idDois: 19, 
    nameDois: 'LIMPA PISO AZULIM 1L LAVANDA', 
    imageUrlDois: 'https://64.media.tumblr.com/8fb2012a09e7ae3c306f707139d030c0/f384e1e0a2c8bee9-c6/s250x400/cbc52dc381863010a8397b8befbe5f61ea04b2c5.pnj' 
  },
  { idDois: 20, 
    nameDois: 'ODORIZADOR LAVANDA CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/ab3df31ab27cdd16d6869ab17e8a0ff1/f384e1e0a2c8bee9-e5/s250x400/acaefa05ed1b05a71d3ae9f5bb953864a6d34272.pnj' 
  },
  { idDois: 21, 
    nameDois: 'MIRAX DESINF ALTA DILUICAO FLORAL BOUQUET 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/3661158f140994d54caee9848ff45fa3/f384e1e0a2c8bee9-65/s250x400/72fedfd5ce0addb46e83abc2cb80f8a13e356036.pnj' 
  },
  { idDois: 22, 
    nameDois: 'MIRAX DESINF ALTA DILUICAO ROSE PREMIUM 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/bbab5b85115d9612d12e4e495dc6e107/f384e1e0a2c8bee9-91/s250x400/8844ac8c152561a1e9282b32390701c153c0a8bc.pnj' 
  },
  { idDois: 23, 
    nameDois: 'NEUTRALIZADOR ODORES BOUQUET 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/edc001c99630067fdb73e463e137d1e6/f384e1e0a2c8bee9-3b/s250x400/8ede189c039a61ee27e02b6fabceb354a0e5f19e.pnj' 
  },
  { idDois: 24, 
    nameDois: 'ODORIZADOR FLORAL CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/9e32dcede1d0a9c783685772a69b60dc/f384e1e0a2c8bee9-45/s250x400/ceb13872064fca3c8e69c6521fd0ba672e6e57f5.pnj' 
  },
  { idDois: 25, 
    nameDois: 'RESTAURADOR DE BRILHO CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/0092e73f6a775ae67067d8a015b8e081/f384e1e0a2c8bee9-ea/s250x400/d72c8d0631c8c260e4dccd66f9e67bc62d3bde33.pnj' 
  },
  { idDois: 26, 
    nameDois: 'SABONETE ERVA DOCE CORDEX 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/0669b1b87f5117fc35e0a32cafc9339d/f384e1e0a2c8bee9-2a/s250x400/6a2c75dc9971e17575eb7b9d591791b402bab18a.pnj' 
  },
  { idDois: 27, 
    nameDois: 'DESENGRAXAX AUDAX5L', 
    imageUrlDois: 'https://64.media.tumblr.com/67eace40e99fa0f0bc0f4a2e523ee303/f384e1e0a2c8bee9-ef/s250x400/3c13396e55bbfe42848229a72a91abc71f78ff37.pnj' 
  },
  { idDois: 28, 
    nameDois: 'LIMP PESADA VEJA 500ML CAMPESTRE', 
    imageUrlDois: 'https://64.media.tumblr.com/31a55406f3b21d9139eee70fb975113c/f384e1e0a2c8bee9-98/s250x400/2269928aff586e9f51817ff37de397c582a49c8f.pnj' 
  },
  { idDois: 29, 
    nameDois: 'VEJA VIDREX REFIL CRISTAL', 
    imageUrlDois: 'https://64.media.tumblr.com/9788e278092998c45daf161793432493/f384e1e0a2c8bee9-86/s250x400/45d9692a2cf68a6ab417c318ffbbce06d00444a9.pnj' 
  },
  { idDois: 30, 
    nameDois: 'VEJA VIDREX REFIL 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/08ce771fe4caf832daff683a66a5b823/f384e1e0a2c8bee9-cd/s250x400/b936fad51a5681d34ab00372aba4d83e4f797240.pnj' 
  },
  { idDois: 31, 
    nameDois: 'VEJA VIDREX TRAD', 
    imageUrlDois: 'https://64.media.tumblr.com/07ada052d7a7c7f0843275d23dab327b/f384e1e0a2c8bee9-b8/s250x400/05553f0104b9b311402e5bd575be24193004df96.pnj' 
  },
  { idDois: 32, 
    nameDois: 'LIMP VIDROS VEJA VIDREX CRIST PULV 30%D', 
    imageUrlDois: 'https://64.media.tumblr.com/b487a63bab20b69c78b90fdd9a05459f/f384e1e0a2c8bee9-91/s250x400/55c839150d0b5a01b1d1bb2bb7878ba3a764401a.pnj' 
  },
  { idDois: 33, 
    nameDois: 'ALCOOL LIQ TUPI 70 1L', 
    imageUrlDois: 'https://64.media.tumblr.com/fc30a4565830a336a41255ca34367a48/c82f7d0e21db12ed-89/s250x400/80b5835f82014eaa4212781b384d3497bcdaff04.pnj' 
  },
  { idDois: 34, 
    nameDois: 'DESINF ALCOOL LIQ 70% TUPI 12X1L QTD. 2.00 CX', 
    imageUrlDois: 'https://64.media.tumblr.com/0e2fba9a704b2e75a3838c8422d49ccf/c82f7d0e21db12ed-af/s250x400/6194f324548f214d782b151769ed00df6ad29bd0.pnj' 
  },
  { idDois: 35, 
    nameDois: 'DESINFETANTE PINHO 500ML LAVANDA', 
    imageUrlDois: 'https://64.media.tumblr.com/96c3d29eda2e1f1d4d36dc981a49eba7/c82f7d0e21db12ed-c1/s250x400/c6004369f5ab5821f033061b0d9fb41c12179fad.pnj' 
  },
  { idDois: 36, 
    nameDois: 'LIMPA PEDRAS PEDREX 2L', 
    imageUrlDois: 'https://64.media.tumblr.com/95739c89593c5de67d551d9c6409ef3b/c82f7d0e21db12ed-3c/s250x400/eb570dea2b8c65eda473adf7a2f73aaab24c5a9b.pnj' 
  },
  { idDois: 37, 
    nameDois: 'LIMPA PISO AZULIM 1L LAVANDA', 
    imageUrlDois: 'https://64.media.tumblr.com/8e627c057ee59a05ef6f2fccbbddae9e/c82f7d0e21db12ed-eb/s250x400/f6a6b36b777d7d1b265018e1b92047124604456f.pnj' 
  },
  { idDois: 38, 
    nameDois: 'LIMPA ALUMINIO START 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/df0fcab171de61add52f7206212bfb67/c82f7d0e21db12ed-59/s250x400/1ed779575c70573a20187ddd6521901a7b334131.pnj' 
  },
  { idDois: 39, 
    nameDois: 'LIMPA INOX AUDAX 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/3ca6ff8d56abf5361542b81ab8fd5533/c82f7d0e21db12ed-8d/s250x400/eb30378abc6fccda10fd20ddd7505148d30159fc.pnj' 
  },
  { idDois: 40, 
    nameDois: 'LIMP.CASA & PERFUME SENSUALIDAD 1L', 
    imageUrlDois: 'https://64.media.tumblr.com/22d8924da73bf306b9a036a6bfd07a21/c82f7d0e21db12ed-3d/s250x400/1fc67b0d94d57dd28d08ae0249bd84b0e3c789e5.pnj' 
  },
  { idDois: 41, 
    nameDois: 'LIMPA VIDROS VEJA VIDREX 500ML C/ ALCOOL', 
    imageUrlDois: 'https://64.media.tumblr.com/42603f106e05cc7585448170b6846101/c82f7d0e21db12ed-e2/s250x400/2aa141142f6d8d97588c47d3ec71d6248498c0b8.pnj' 
  },
  { idDois: 42, 
    nameDois: 'VEJA 2LT', 
    imageUrlDois: 'https://64.media.tumblr.com/e640b7940533d377ab450312260b1460/c82f7d0e21db12ed-94/s250x400/ffa46bcfda5161faa4d239aa5a9e53f4ca23013f.pnj' 
  },
  { idDois: 43, 
    nameDois: 'TEX ALCOOL TRADICIONAL 1 LITRO', 
    imageUrlDois: 'https://64.media.tumblr.com/8261dcc6249ef4a4f5579428805e9e76/c82f7d0e21db12ed-12/s250x400/6bbe7d9f89e3c5c9b4499b5b46309284db681004.pnj' 
  },
  { idDois: 44, 
    nameDois: 'TIRA LIMO WORKER 12X500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/23c4fd479acf0d09a216d8d184ccd807/c82f7d0e21db12ed-74/s250x400/021f31d7b63bc19b24d158aaa87cd4084ac90f5e.pnj' 
  },
  { idDois: 45, 
    nameDois: 'SAPON CREM SAPOLIO RADIUM 250ML CLASSICO', 
    imageUrlDois: 'https://64.media.tumblr.com/8507811472619ace1085cc55778560d5/c82f7d0e21db12ed-2b/s250x400/d2014ff18a5783324623d2e3ff30f580c2ef2b5a.pnj' 
  },
  { idDois: 46, 
    nameDois: 'MULTIUSO VEJA 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/c1d4fc619fa451836062528e428e163c/c82f7d0e21db12ed-30/s250x400/c36062daea1fa2ca97efa9d2a104c3411cfd75bc.pnj' 
  },
  { idDois: 47, 
    nameDois: 'MULTIUSO VEJA CAMPESTRE 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/f4ca08ad94d8a1d25418dc0d84709067/c82f7d0e21db12ed-e8/s250x400/44c4e27b7cc9ee1c24a46a22af44d996f67007fc.pnj' 
  },
  { idDois: 48, 
    nameDois: 'PINHO SOL LAVANDA 1LT', 
    imageUrlDois: 'https://64.media.tumblr.com/36fb7af4c4db0a60d2bfa280eb3be571/c82f7d0e21db12ed-7f/s250x400/5ec243c6c49e2bc48bc6fc1e57ad7790a465eb3a.pnj' 
  },
  { idDois: 49, 
    nameDois: 'LYSOFORM SUAVE', 
    imageUrlDois: 'https://64.media.tumblr.com/95231217dd48e7bae9050e4efa2209d5/c82f7d0e21db12ed-87/s250x400/2a94b067413920477560f46c531897068653073e.pnj' 
  },
  { idDois: 50, 
    nameDois: 'DES BOM AR AER 360ML ROMANCE', 
    imageUrlDois: 'https://64.media.tumblr.com/fe346d0aac8b301e7d60b40fde55fc4c/8f2f6c7d9401780f-2b/s250x400/414c08d04de0c7e4ebc3c9fe0a325fdc6e92e97e.pnj' 
  },
  { idDois: 51, 
    nameDois: 'DESODORIZADOR GLADE LAVANDA 360ML', 
    imageUrlDois: 'https://64.media.tumblr.com/55a370d8dce255b160c0d10457522427/8f2f6c7d9401780f-7b/s100x200/53fe099e7f396c9c917553bcdafeac29970f0911.pnj' 
  },
  { idDois: 52, 
    nameDois: 'DESODORIZADOR BOM AR FLOR DE ALGODÃO 360ML', 
    imageUrlDois: 'https://64.media.tumblr.com/0760324f11c6715b60b8ebd084fd9016/8f2f6c7d9401780f-08/s250x400/e94f4117f20e7612c76bd70370329d70a936f876.pnj' 
  },
  { idDois: 53, 
    nameDois: 'ODORIZANTE AMB SECAR SENSAC 360ML ALGODAO', 
    imageUrlDois: 'https://64.media.tumblr.com/6e0a7b7d1642c1c06f9681986058458c/8f2f6c7d9401780f-08/s250x400/1da699a32de7b1e9a2c2b8f1f30c1526a10364f3.pnj' 
  },
  { idDois: 54, 
    nameDois: 'DESODORIZADOR GLADE REFIL AGUAS FLORAIS 269ML', 
    imageUrlDois: 'https://64.media.tumblr.com/c1fac78512a914d8f63ea4cf9043fbc4/8f2f6c7d9401780f-09/s250x400/2a8bf1c14ee52880f28e89bc56ac7a625cf36d00.pnj' 
  },
  { idDois: 55, 
    nameDois: 'DETERGENTE LIQUIDO ODD 500ML ORIGINAL', 
    imageUrlDois: 'https://64.media.tumblr.com/57026b0b434da92b94722630d7862fb7/8f2f6c7d9401780f-b5/s250x400/ba348374f03b111cb87a8d624cebc8df82cdc24a.pnj' 
  },
  { idDois: 56, 
    nameDois: 'AROMATIZANTE DE AMBIENTES 140ML', 
    imageUrlDois: 'https://64.media.tumblr.com/b6a90e91717d0eddabd31629b9d4e9a4/8f2f6c7d9401780f-36/s250x400/ffeef35ee235652b55a96435e9f35d1570b3310a.pnj' 
  },
  { idDois: 57, 
    nameDois: 'DESOD. SECAR AERO KIDS', 
    imageUrlDois: 'https://64.media.tumblr.com/22c0e2b5831f1b11426db5f41cb8e27d/8f2f6c7d9401780f-0a/s250x400/15da3e6471c2c8d8ead0b8cb9484e87d5029010c.pnj' 
  },
  { idDois: 58, 
    nameDois: 'CERA LIQ BRILHO FACIL 750ML INCOLOR', 
    imageUrlDois: 'https://64.media.tumblr.com/6280ce54de0ebab7cb614e56107410e4/8f2f6c7d9401780f-d5/s250x400/13dc9759427a201f2dddb075dd89dcf7d3cb9850.pnj' 
  },
  { idDois: 59, 
    nameDois: 'CIF CREMOSO BRANCO 450ML', 
    imageUrlDois: 'https://64.media.tumblr.com/a480591bde4747c30fd7d85f473a4c57/8f2f6c7d9401780f-35/s250x400/135c10ce471ca0bbdc3b1a9f49d0fbea49b2f957.pnj' 
  },
  { idDois: 60, 
    nameDois: 'CIF. CREMOSO BRANCO 250ML', 
    imageUrlDois: 'https://64.media.tumblr.com/2985bd3e56dab17f6433bd35ef469ea8/8f2f6c7d9401780f-12/s250x400/001890b839ea9119811731e6250d2d145a3fbca4.pnj' 
  },
  { idDois: 61, 
    nameDois: 'BRILHA INOX WORKER 12X300ML', 
    imageUrlDois: 'https://64.media.tumblr.com/cdf3bc6c29ee7199c9d26da922d1b934/8f2f6c7d9401780f-05/s250x400/10005759e59b4f913d029e219e00fb9f161eca37.pnj' 
  },
  { idDois: 62, 
    nameDois: 'ARDOSIA LUX 1KG', 
    imageUrlDois: 'https://64.media.tumblr.com/701fda4068bdd0753091e7a39f0a0bd2/8f2f6c7d9401780f-c4/s250x400/7b7566dd75d237a710b1caffccfbf382797d0db7.pnj' 
  },
  { idDois: 63, 
    nameDois: 'CERA BRILHO FACIL AMARELA​', 
    imageUrlDois: 'https://64.media.tumblr.com/275d82845e0905cc6c2902e7bac8851e/8f2f6c7d9401780f-dd/s250x400/9d644ea00b4c10e50011667f6d2568da5cf8ca39.pnj' 
  },
  { idDois: 64, 
    nameDois: 'LIMP.P/PISO DESTAC P/LAMINADO 750ML', 
    imageUrlDois: 'https://64.media.tumblr.com/45c0c77879e7d355ff98eb4fd6a6435e/8f2f6c7d9401780f-b0/s250x400/e00a52c2943a83128c83506b229bd47ae4551efa.pnj' 
  },
  { idDois: 65, 
    nameDois: 'LIMPA CARPETE WORKER 12X500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/df6c8854f29fcc58fc8a8913a5edbae7/8f2f6c7d9401780f-cb/s250x400/660f14f000bc71b6b1dd3ac328ce875378b06128.pnj' 
  },
  { idDois: 66, 
    nameDois: 'LUSTRA MOV. WORKER 500ML FLORAL', 
    imageUrlDois: 'https://64.media.tumblr.com/1b55b40ee47ee1a5929afbafd702a55e/ddde588bf9a884b0-71/s250x400/64d443440bce466af9b5d2d4124ed1e3eaf3f99e.pnj' 
  },
  { idDois: 67, 
    nameDois: 'LUSTRA MOVEIS POLIFLOR 500ML LAY', 
    imageUrlDois: 'https://64.media.tumblr.com/512b797d22790b8c35ded08f906874ae/ddde588bf9a884b0-15/s250x400/778754954d3183c1798fd605cc9603e99f87dec0.pnj' 
  },
  { idDois: 68, 
    nameDois: 'POLIDOR BRASSO 200ML', 
    imageUrlDois: 'https://64.media.tumblr.com/35119ba179097f1536070f41b0133a7f/ddde588bf9a884b0-33/s250x400/73d57b5a38d4b80f3278608194ca185b4c5107c9.pnj' 
  },
  { idDois: 69, 
    nameDois: 'ESSENCIA DE EUCALIPTO EXTRA FORTE TERPE', 
    imageUrlDois: 'https://64.media.tumblr.com/b2702d25bc5ed6b3da06f35a6eb8fe2f/ddde588bf9a884b0-8a/s250x400/01e76e36e4bfe9ae5e4e074b947c2407a6f3f877.pnj' 
  },
  { idDois: 70, 
    nameDois: 'HIG ASSEPTGEL CRISTAL', 
    imageUrlDois: 'https://64.media.tumblr.com/3026035a07e21ddae0258bb1d0bbed06/ddde588bf9a884b0-6c/s250x400/b1b5f987e5158454503165772ef0c04deafbdcea.pnj' 
  },
  { idDois: 71, 
    nameDois: 'DESENGRI/ANTICORROSIVO 300ML INCOL CHESY', 
    imageUrlDois: 'https://64.media.tumblr.com/daa82fedf147238f0cc647d38e74e03f/ddde588bf9a884b0-c2/s250x400/68a346ae859d6beb09182a8decd1d4e9497b648e.pnj' 
  },
  { idDois: 72, 
    nameDois: 'FAISCA BARRA 1LT', 
    imageUrlDois: 'https://64.media.tumblr.com/d7525a70872bed0c5cccabd4723e77d4/ddde588bf9a884b0-2a/s250x400/7757ddaa93acd66fb90cddb864cb19870047d143.pnj' 
  },
  { idDois: 73, 
    nameDois: 'ÓLEO ANTICORROSIVO 300ML M1 STARRETT', 
    imageUrlDois: 'https://64.media.tumblr.com/b454476762291a34d24c3324c74f57c4/ddde588bf9a884b0-94/s250x400/afe65e19625026ea4b97a63983694689b7e040b0.pnj' 
  },
  { idDois: 74, 
    nameDois: 'WD-40 DESENGRIPANTE', 
    imageUrlDois: 'https://64.media.tumblr.com/1777d6fdbb5f7b6f231e52d0feafd091/ddde588bf9a884b0-02/s250x400/cd6b8ca64bbf5ffbaa335bfc61a054e050e420bf.pnj' 
  },
  { idDois: 75, 
    nameDois: 'REMOVEDOR KING PLUS 500ML', 
    imageUrlDois: 'https://64.media.tumblr.com/c9075116623b28e683c3679aaa082342/ddde588bf9a884b0-1f/s250x400/a3179b8417f4bccc6df4faaa24dd2188a6d4c704.pnj' 
  },
  { idDois: 76, 
    nameDois: 'REMOVEDOR TUPI 1L TRAD', 
    imageUrlDois: 'https://64.media.tumblr.com/8066b3848cbd732d7ee62cd43a34b5f8/ddde588bf9a884b0-a2/s250x400/40583e59f4be61ccb02c02747a17d1e41f32b8b4.pnj' 
  },
  { idDois: 76, 
    nameDois: 'REMOVEDOR ZULU 450ML PERFUMADO', 
    imageUrlDois: 'https://64.media.tumblr.com/9d0146de153c38c29cb8346f41b1e012/ddde588bf9a884b0-13/s250x400/d37b86358f5a46a5a950454457d2e08eefb71261.pnj' 
  },
  { idDois: 77, 
    nameDois: 'QUEROSENE TUPI 1L TRAD', 
    imageUrlDois: 'https://64.media.tumblr.com/936ca1f80fa75558d343f9f1b86288af/ddde588bf9a884b0-9e/s250x400/052e9d098a4a0a53c3973fc00529e4359c4ca3d6.pnj' 
  },
  { idDois: 78, 
    nameDois: 'REMOVEDOR FAISCA PET 1000ML', 
    imageUrlDois: 'https://64.media.tumblr.com/49dee503eafb4526fe95befdb3f5627b/ddde588bf9a884b0-22/s250x400/efe6c815c24d830e5af301a423ee27e9ad9ff92f.pnj' 
  },
  { idDois: 79, 
    nameDois: 'REMOVEDOR JACARE 900ML(RAFBRAS)', 
    imageUrlDois: 'https://64.media.tumblr.com/10c931bae93c961b63a28efa90b6991f/ddde588bf9a884b0-3c/s250x400/3342a2ab09affc452b676fdd85e19509aac0a9ba.pnj' 
  },
  { idDois: 80, 
    nameDois: 'SOLVENTE JACARE CX 12X900 ML', 
    imageUrlDois: 'https://64.media.tumblr.com/364128ec4d582fb5df6435e88a055d94/ddde588bf9a884b0-9e/s250x400/5931a61329ee0f5e0b3f0b1f9143fea3b17c6d3d.pnj' 
  },
  { idDois: 81, 
    nameDois: 'QUEROSENE RAFBRAS 900ML', 
    imageUrlDois: 'https://64.media.tumblr.com/129454d1952516cebd7c2c40869412b1/ddde588bf9a884b0-aa/s250x400/4a7802d614a3bd09b71e630d0613925f728a5280.pnj' 
  },
  {
    idDois: 82, 
    nameDois: 'Hipoclorito de Sódio Audax 5L', 
    imageUrlDois: 'https://64.media.tumblr.com/1a7ddb7e0626e5a56a8f979746c686f2/20847a47715aab9e-76/s250x400/b85550390aef72ab142e15dea053d406696e62f3.pnj' 
  },
];

export default mockFloorProducts;