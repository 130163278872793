import "./ButtonContact.scss";

const ButtonContact = () => {
  return (
    <div className="btn-contact-content">
      <a className="btn-contact" href="https://wa.me/5521977365704?text=" target="_blank" rel="noreferrer">
        Whatsapp →
      </a>
    </div>
  );
};

export default ButtonContact;