import React from 'react';
import './SliderProducts.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import Brooms from '../MockProducts/Brooms';

import Desinfetantes from '../MockProducts/Desinfetantes';
import mockFloorProducts from '../../Assets/mockProduct/mockFloorProducts';

import Banheiro from '../MockProducts/Banheiro';
import mockToiletProducts from '../../Assets/mockProduct/mockToiletProducts';

import Sabonete from '../MockProducts/Sabonete';
import mockSoapProducts from '../../Assets/mockProduct/mockSoapProducts';

import Baldes from '../MockProducts/Baldes';
import mockBucketProducts from '../../Assets/mockProduct/mockBucketProducts';

import Esponjas from '../MockProducts/Esponjas';
import mockSpongeProducts from '../../Assets/mockProduct/mockSpongeProducts';

import Panos from '../MockProducts/Panos';
import mockClothProducts from '../../Assets/mockProduct/mockClothProducts';

import Pastilhas from '../MockProducts/Pastilhas';
import mockTabletsProducts from '../../Assets/mockProduct/mockTabletsProducts';

import Luvas from '../MockProducts/Luvas';
import mockGlovesProducts from '../../Assets/mockProduct/mockGlovesProducts';
import Lampadas from '../MockProducts/Lampas';
import mockLampsProducts from '../../Assets/mockProduct/mockLampsProducts';
import Piscina from '../MockProducts/Piscina';
import mockPoolProducts from '../../Assets/mockProduct/mockPoolProducts';
import Diversidade from '../MockProducts/Diversidades';
import mockDiversityProducts from '../../Assets/mockProduct/mockDiversityProducts';
import SacoDeLixo from '../MockProducts/SacoDeLixo';
import mockSacoDeLixoProducts from '../../Assets/mockProduct/mockSacoDeLixo';

interface Product {
  id: number;
  name: string;
  imageUrl: string;
}

interface ShowCaseProps {
  mockProducts: Product[];
  mockFloorProducts: Product[];
}

const SliderProducts: React.FC<ShowCaseProps> = ({ mockProducts }) => {
  return (
    <div className="slider-product-container">
      <div id="SliderProducts" className="slider-product-container__section-title">
        
        <div className="slider-wrapper">
          <Brooms mockProducts={mockProducts} />
          <Desinfetantes mockProductsFloor={mockFloorProducts} />
          <Banheiro mockToiletProducts={mockToiletProducts} />
          <Sabonete mockSoapProducts={mockSoapProducts} />
          <Baldes mockBucketProducts={mockBucketProducts} />
          <Esponjas mockSpongeProducts={mockSpongeProducts} />
          <Panos mockClothProducts={mockClothProducts} />
          <Pastilhas mockTabletsProducts={mockTabletsProducts} />
          <Luvas mockGlovesProducts={mockGlovesProducts} />
          <Lampadas mockLampsProducts={mockLampsProducts} />
          <Piscina mockPoolProducts={mockPoolProducts} />
          <Diversidade mockDiversityProducts={mockDiversityProducts} />
          <SacoDeLixo mockSacoDeLixoProducts={mockSacoDeLixoProducts} />
        </div>
      </div>
    </div>
  );
};

export default SliderProducts;
