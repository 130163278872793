const mockToiletProducts = [
  { idTres: 1, 
    nameTres: 'PAPEL HIG SUBLIME 30M C/4 FOLHA DUPLA', 
    imageUrlTres: 'https://64.media.tumblr.com/0fddb55920ec2fe508ef82e0abb37be5/495b00c878bf783b-e6/s250x400/33bab63f8a3e6adc80658ef43eb9cd1c01818682.pnj' 
  },
  { idTres: 2, 
    nameTres: 'PAPEL HIG.ROLÃO XANDY 100% CELULOSE 300X10 - 8 UND', 
    imageUrlTres: 'https://64.media.tumblr.com/28561df3a6034faccffe2a13e6877364/495b00c878bf783b-3a/s250x400/36b07bbe7214da5e884e8418850d1596a873af32.pnj' 
  },
  { idTres: 3, 
    nameTres: 'PAPEL HIGIENICO ROLAO BELIPEL GOLD LUXO 8X300 FL SIMPLES CIPEL', 
    imageUrlTres: 'https://64.media.tumblr.com/b0b06b020f721ed6b3fbcc44e6dc275e/495b00c878bf783b-18/s250x400/47e57859765fa0a8fc4be825700c94e31f39ed9b.pnj' 
  },
  { idTres: 4, 
    nameTres: 'PAPEL HIGIENICO MIMMO FOL DUPLA 30M C/ 16', 
    imageUrlTres: 'https://64.media.tumblr.com/8d8fca81e4708161c5fa2e56fca6128d/495b00c878bf783b-5d/s400x600/15a88a5ee402d7baa7efd0b03e1d247a44d88bfa.pnj' 
  },
  { idTres: 5, 
    nameTres: 'PAPEL HIGIENICO NEVE C12 ROLOS 30MTS', 
    imageUrlTres: 'https://64.media.tumblr.com/83ab005d4cad3eef4d1e6bc3a8133636/495b00c878bf783b-b2/s400x600/9df0d1fac10856a2d0379798c2bffc4d5b62b612.pnj' 
  },
  { idTres: 6, 
    nameTres: 'PAPEL HIGIENICO ROLAO FARDO C/ 8 ROLOS IMPERIO', 
    imageUrlTres: 'https://64.media.tumblr.com/3626cf73eb62d14f2720de2c0df8878e/495b00c878bf783b-21/s250x400/52a82a942c216f5f6cde32a4686fb7b92f35fb8d.pnj' 
  },
  { idTres: 7, 
    nameTres: 'PAPEL HIG COTTON FD 30M L12 P11', 
    imageUrlTres: 'https://64.media.tumblr.com/a0637a672185dcc69209550e598530db/495b00c878bf783b-3f/s400x600/23af7780e2d4cf8c806f71357614b1b02efaeb00.pnj' 
  },
  { idTres: 8, 
    nameTres: 'PAPEL HIG PERSONAL 20M C/4 FOLHA DUPLA', 
    imageUrlTres: 'https://64.media.tumblr.com/86af1a03e7d443934e51c5da84bab0fc/495b00c878bf783b-75/s250x400/f68d696a6cad7564470794f54d3d2e950581bd3f.pnj' 
  },
]

export default mockToiletProducts