const mockPoolProducts = [
  {
    idOnze: 2, 
    nameOnze: 'HTH Clarifica Maxfloc 5Lts', 
    imageUrlOnze: 'https://64.media.tumblr.com/c6619249bca33974d763addfaf3592b9/20847a47715aab9e-c0/s250x400/370406facb9ddc5493a35f7247a8db78b815a71a.pnj' 
  },
  {
    idOnze: 3, 
    nameOnze: 'HTH Algicida Choque Refil 900ml', 
    imageUrlOnze: 'https://64.media.tumblr.com/61be0267e501d1fbdef894f91c6ba746/20847a47715aab9e-4f/s250x400/cb13a5ff676d6ba09d5a075f088add84915a1c06.pnj' 
  },
  {
    idOnze: 4, 
    nameOnze: 'HTH Clarifica Maxfloc 5Lts', 
    imageUrlOnze: 'https://64.media.tumblr.com/b2b9947a671ca195ecddd569beffe446/20847a47715aab9e-3c/s250x400/df152a8858595ff00cf0a094bddf4cbe097a1aca.pnj' 
  },
  {
    idOnze: 5, 
    nameOnze: 'Marisol Peneira Suzi\'s Cabo', 
    imageUrlOnze: 'https://64.media.tumblr.com/bb0016e7f661af707d28477eb137844b/20847a47715aab9e-47/s400x600/9f2188ad0b14fe646a6667a96c66d9dd7c266b0e.pnj' 
  },
  {
    idOnze: 6, 
    nameOnze: 'Suall Barrilha 2kg', 
    imageUrlOnze: 'https://64.media.tumblr.com/2fa336ce3abca3c8ed369df260ba9d17/20847a47715aab9e-4b/s250x400/7393f313cb8266696530bc46645d3ae67ec5cd28.pnj' 
  },
  {
    idOnze: 7, 
    nameOnze: 'Suall Sulfato de Alumínio 2kg', 
    imageUrlOnze: 'https://64.media.tumblr.com/97eed69f7ffa7d77bf765dbf40f810cc/20847a47715aab9e-e0/s250x400/1d8a14977479b4886f0ad732a447d897ca1329cd.pnj' 
  },
  {
    idOnze: 8, 
    nameOnze: 'Esguicho Espigão Zamac 1/2 Garden', 
    imageUrlOnze: 'https://64.media.tumblr.com/d93ac047dfd360a61eb7d396e4d3be4c/20847a47715aab9e-51/s250x400/bc3fadc17aa904839bbe71fc40eed645c48afc02.pnj' 
  },
  {
    idOnze: 9, 
    nameOnze: 'HTH Cloro Mineral Brilliance 10kg', 
    imageUrlOnze: 'https://64.media.tumblr.com/bf1e71a36c9e44f4cf0e9d5e072a2a40/20847a47715aab9e-48/s250x400/73ab4f3a94e54f3f87ffcfde48aaa0e25ddc77c2.pnj' 
  },
  {
    idOnze: 10, 
    nameOnze: 'HTH Elevador de Alcalinidade 2kg', 
    imageUrlOnze: 'https://64.media.tumblr.com/da57d0124ab46fbb766c0e3ac66c9f3e/20847a47715aab9e-4b/s400x600/b687eba58b832bdc6c58fdf9eb886e95e5f26113.pnj' 
  },
  {
    idOnze: 11, 
    nameOnze: 'HTH Elevador de pH Pct. 1.5kg', 
    imageUrlOnze: 'https://64.media.tumblr.com/5f8acf68f4d99c4e3c3870eb1a68d368/20847a47715aab9e-0a/s250x400/3d6fd6301c80480e7f8a0a68b1ae724f3d263252.pnj' 
  },
  {
    idOnze: 12, 
    nameOnze: 'HTH Limpa Borda 1L', 
    imageUrlOnze: 'https://64.media.tumblr.com/4dff400857ffdbc883c68f530f1d05f7/20847a47715aab9e-ed/s250x400/d7e5574b75b12fda9060ace5faa834443fbf373d.pnj' 
  },
  {
    idOnze: 13, 
    nameOnze: 'HTH Pace Ação Total 10kg Diclor', 
    imageUrlOnze: 'https://64.media.tumblr.com/2e9a3691b282a83a9c20e406d4f99229/20847a47715aab9e-f8/s250x400/c0afa5e164a49bfbb76df0257ae77d6bb2787205.pnj' 
  },
  {
    idOnze: 14, 
    nameOnze: 'HTH Pace Tripla Ação 200g', 
    imageUrlOnze: 'https://64.media.tumblr.com/bbb0e8cdd2a639899557d9fa8ee8b20f/20847a47715aab9e-a9/s250x400/90bfbedde8cfa9680aa7aaf305e4004a01201c09.pnj' 
  },
  {
    idOnze: 15, 
    nameOnze: 'HTH Previne Água Verde Refil 900ml', 
    imageUrlOnze: 'https://64.media.tumblr.com/601200e86acd4897d7b4ca6e9fbc3870/20847a47715aab9e-6a/s250x400/de0b9e620695cb2c584fa8555d482cab2f160cf6.pnj' 
  },
  {
    idOnze: 16, 
    nameOnze: 'Innovation Impermeabilizante Flash 3em1 5L', 
    imageUrlOnze: 'https://64.media.tumblr.com/3865519c061710aab5472f881e303989/20847a47715aab9e-e5/s250x400/117bb6ddd49336772220047b232c627f9f84b143.pnj' 
  }
]

export default mockPoolProducts
