const mockClothProducts = [
  { 
    idSete: 1, 
    nameSete: 'PANO M USO LIMPPANO FURATTO AZUL 5UN', 
    imageUrlSete: 'https://64.media.tumblr.com/8a58f85b1f1484ce993fb4e729093149/c328ad391a1b900d-d5/s250x400/1ef3628c151b269cc8f2e340505b98b166bca5a9.pnj' 
  },
  { 
    idSete: 2, 
    nameSete: 'PANO DE MICROFIBRA 30X30CM (PACOTE C/2UNID.) VERDE - NOBRE', 
    imageUrlSete: 'https://64.media.tumblr.com/fcfb889b42aafd58bdf60ca612d0d0a9/c328ad391a1b900d-42/s250x400/3b4912bbe4cdf938f3addbdb08e272e50911ae22.pnj' 
  },
  { 
    idSete: 3, 
    nameSete: 'PANO MULTIUSO (PACOTE C/5UNID. 30CM X 50CM) AZUL - NOBRE', 
    imageUrlSete: 'https://64.media.tumblr.com/4a1e65cb041008fe02f58326ddece50a/c328ad391a1b900d-4d/s250x400/41b015acd3369e6809b27a41156e5bedae921a51.pnj' 
  },
  { 
    idSete: 4, 
    nameSete: 'PANO LIMPEZA LEVE ALKLIN 33X50 C/5 AZUL', 
    imageUrlSete: 'https://64.media.tumblr.com/ee63d4cb7460d3facad727f8fc73588e/c328ad391a1b900d-3b/s250x400/51d521fa20d1d541466fe8b891851f8b1bd24b1e.pnj' 
  },
  { 
    idSete: 5, 
    nameSete: 'PANO DE LIMPEZA ALVEJADO TAMANHO M 43X68', 
    imageUrlSete: 'https://64.media.tumblr.com/4c9e6406c01abce870fb559f18731303/c328ad391a1b900d-33/s400x600/14f0eaa6b2c8f9560af0ee9d1b66eff9c9a8cc76.pnj' 
  },
  { idSete: 6, 
    nameSete: 'PANO DE CHAO GG ( ALVEJADO) 50X76', 
    imageUrlSete: 'https://64.media.tumblr.com/acf3d28bce2de1fc87e4ff8ed7c7b685/c328ad391a1b900d-e9/s400x600/cbc36922c6aeb03421ab131bd331b0a959970278.pnj' 
  },
  { 
    idSete: 7, 
    nameSete: 'FLANELA OURO G (LIMPEZA)', 
    imageUrlSete: 'https://64.media.tumblr.com/a702e7d8e7741b9a5757f68dfe9bdfc9/c328ad391a1b900d-a3/s400x600/35477d3df555797ee0b43b697835e0b5a89199a3.pnj' 
  },
  { 
    idSete: 8, 
    nameSete: 'SACO ALVEJADO (EMILIMP) 45X76', 
    imageUrlSete: 'https://64.media.tumblr.com/1ee7c194f2fb8320964e03b3d457a589/c328ad391a1b900d-49/s400x600/c277659cb7878f1befff762786c0a55dc57cc44c.pnj' 
  },

]

export default mockClothProducts