const mockDiversityProducts = [
  {
    idDoze: 1, 
    nameDoze: 'SODA ESCAMA VOREL 500G', 
    imageUrlDoze: 'https://64.media.tumblr.com/5ae2014137ffd0e5abbfe7585b5ca27c/c4bad9a5e5c70b97-e3/s250x400/74f33514502c3763d6e3225f67970b3b0388ca28.pnj' 
  },
  {
    idDoze: 2, 
    nameDoze: 'NAFTALINA K- AROMA 40 G', 
    imageUrlDoze: 'https://64.media.tumblr.com/63483aad509cce51bdb08287aab0bb52/c4bad9a5e5c70b97-bd/s400x600/93c499cc61ea695fe1d810f5df4e4b0b20e50c5c.pnj' 
  },
  {
    idDoze: 3, 
    nameDoze: 'REFIL DE MICROFIBRA P/ CONJUNTO ROTATORIO SLIM/INOX', 
    imageUrlDoze: 'https://64.media.tumblr.com/0dd2b89d604c03e1aaef8d655d1e1395/c4bad9a5e5c70b97-4a/s250x400/5957492708bc9e483646531febf110fc0cf4cd69.pnj' 
  },
  {
    idDoze: 4, 
    nameDoze: 'RODO DE PIA PLASTICO', 
    imageUrlDoze: 'https://64.media.tumblr.com/71599aa20271b792cb8028eb01c46246/c4bad9a5e5c70b97-36/s250x400/c0026f7b0ed4a5087c6953780d3f41ffb33e222a.pnj' 
  },
  {
    idDoze: 5, 
    nameDoze: 'DISCO LIMPEZA P/ENCERADEIRA VERDE 350MM. - NOBRE', 
    imageUrlDoze: 'https://64.media.tumblr.com/424f2f292ac875e75f5285ccf8d0ae62/c4bad9a5e5c70b97-c7/s250x400/3005eb801317475fac0fc04e792f8e53dda4bf42.pnj' 
  },
  {
    idDoze: 6, 
    nameDoze: 'DISCO LIMPADOR VD 350MM BT', 
    imageUrlDoze: 'https://64.media.tumblr.com/e53f9cf7e03fe479866eed07683def9f/c4bad9a5e5c70b97-7d/s250x400/37199e2481735a714a9e462948d84820eb9b9d62.pnj' 
  },
  {
    idDoze: 7, 
    nameDoze: 'DISCO LIMPADOR VD 350MM BT', 
    imageUrlDoze: 'https://64.media.tumblr.com/813dd19de7968a2aa3fdefe1ab656f0d/c4bad9a5e5c70b97-0a/s250x400/4cf336aea6a86c594dfe738feda92042b7476d2e.pnj' 
  },
  {
    idDoze: 8, 
    nameDoze: 'DISCO POLIDOR BRANCO', 
    imageUrlDoze: 'https://64.media.tumblr.com/393875893afef5eade7848a26ff60a13/c4bad9a5e5c70b97-11/s250x400/f90852c3725847972333204e14e49b3c5cf84faa.pnj' 
  },
  {
    idDoze: 9, 
    nameDoze: 'ADESIVO 5X10 PARA LIXEIRA', 
    imageUrlDoze: 'https://64.media.tumblr.com/0afc6ad5f97e25d65e06d8e499638544/c4bad9a5e5c70b97-2b/s400x600/dabcecb731900a57c220547e3d1aeb1913f6ae1f.pnj' 
  },
  {
    idDoze: 10, 
    nameDoze: 'DISPENSER P/TOALHA 1/3D. CINZA/BRANCO', 
    imageUrlDoze: 'https://64.media.tumblr.com/6ee4d19eede5fdd61bec70426775d149/c4bad9a5e5c70b97-b6/s250x400/754ccd56f89c7aa70378c2d8831e2005dce6577e.pnj' 
  },
  {
    idDoze: 11, 
    nameDoze: 'INSET AER BAYGON 360ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/0736826036959a946e54265e2a4e756c/c4bad9a5e5c70b97-c5/s250x400/9fdc6299ca5250d78efaf81999a60c62d6b2467a.pnj' 
  },
  {
    idDoze: 12, 
    nameDoze: 'INSET AER SBP MULTINSET 380ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/579c181bd038006b76c7ba490b423bc3/c4bad9a5e5c70b97-9b/s250x400/0caaec133532da8dd897089a29baa8e152ab94a8.pnj' 
  },
  {
    idDoze: 13, 
    nameDoze: 'DISPENSER POUPADOR DE COPOS 150 A 200ML BRANCO', 
    imageUrlDoze: 'https://64.media.tumblr.com/ce92a189c3a6c985ba6d83cc7a7d1221/c4bad9a5e5c70b97-e2/s250x400/36fa55d1a9e16a7b48cab35bdc426212c71aad00.pnj' 
  },
  {
    idDoze: 14, 
    nameDoze: 'VASELINA LIQUIDA MORIÁ 500ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/1d12d138f4b2a775c83fde5fddcb8cb1/c4bad9a5e5c70b97-88/s250x400/65a69606ee816235b35780c59a97edd16de67426.pnj' 
  },
  {
    idDoze: 15, 
    nameDoze: 'BOTA PVC CANO MEDIO PRETO MARLUVAS N42', 
    imageUrlDoze: 'https://64.media.tumblr.com/e9a3cc8f6953cac62e2b9cf82d1e62bf/c4bad9a5e5c70b97-6f/s250x400/317497a275b0686be1f4614ae2a562d2dca50e68.pnj' 
  },
  {
    idDoze: 16, 
    nameDoze: 'SUPERPRO BALDE PLASTICO 14L', 
    imageUrlDoze: 'https://64.media.tumblr.com/3af1eebdc180149a2af06aba0986ed47/c4bad9a5e5c70b97-55/s250x400/d853c568c5b2a3e8f10c5adbbdb8e7ffe4479d39.pnj' 
  },
  {
    idDoze: 17, 
    nameDoze: 'INSET ELET RAID REF 45N GTS APAR', 
    imageUrlDoze: 'https://64.media.tumblr.com/f7acebd4e22f548dfb70fa530a4d7d04/c4bad9a5e5c70b97-40/s250x400/cb4bbf52961295fde763f2a8d895f6141102f27d.pnj' 
  },
  {
    idDoze: 18, 
    nameDoze: 'INSET RAID 45NOITES 32,9ML RF C/2 50% NO SEG', 
    imageUrlDoze: 'https://64.media.tumblr.com/42093b3925ba30a229b3ac9d4f23359e/c4bad9a5e5c70b97-8c/s250x400/b6e88c2c3914e5eb8501a3735798036b3974d5d4.pnj' 
  },
  {
    idDoze: 19, 
    nameDoze: 'INSET ELET SBP REFIL 45N', 
    imageUrlDoze: 'https://64.media.tumblr.com/31ef5038f083aa3d91143fecfcdba149/c4bad9a5e5c70b97-a3/s250x400/105fc1c0c1f42b1d1a0e1b588fbf41ed12732302.pnj' 
  },
  {
    idDoze: 20, 
    nameDoze: 'PAPEL SULFITE 75G A4 CHAMEX', 
    imageUrlDoze: 'https://64.media.tumblr.com/789515e392f5764d9685fe402d5627dc/c4bad9a5e5c70b97-e1/s250x400/a7a835c1d764a93ed2a916cdc6e4ff4d4f792312.pnj' 
  },
  {
    idDoze: 21, 
    nameDoze: 'PAPEL TOALHA INTERFOLHA 100% CELULOSE -XANDY - C/1000', 
    imageUrlDoze: 'https://64.media.tumblr.com/ff41f825e0add1e52440f9c6c71f48ae/c4bad9a5e5c70b97-e4/s250x400/b68a49315c430dfce3d1bcf5e6d611ef0d15c95e.pnj' 
  },
  {
    idDoze: 22, 
    nameDoze: 'PAPEL TOALHA INTERFOLHA BRANCO LUXO -XANDY - C/1000', 
    imageUrlDoze: 'https://64.media.tumblr.com/db8b49a041b7099a0b6f5986223e62ca/c4bad9a5e5c70b97-aa/s400x600/e983d5cfdb83b31fad45449aa4b5b3c77404d1f9.pnj' 
  },
  {
    idDoze: 23, 
    nameDoze: 'PULVERIZADOR 500ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/d8f9557ae63017950e688411f343e8f0/c4bad9a5e5c70b97-c2/s250x400/f787eaeaa5b77930e6d0767a7a91bc0f3f8fd12c.pnj' 
  },
  {
    idDoze: 24, 
    nameDoze: 'RAF VASELINA 450 ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/8d2b5a653b78405af325ab7886c7500a/c4bad9a5e5c70b97-4f/s250x400/ce06b2614697dc8bc0f21ba419579a3908c5e3c5.pnj' 
  },
  {
    idDoze: 25, 
    nameDoze: 'REGADOR 05 LITROS VIERDE', 
    imageUrlDoze: 'https://64.media.tumblr.com/b2c25c879df3d2f61beaeac6a446a494/c4bad9a5e5c70b97-8d/s250x400/8222698bef21279a0b305f49d2513c2efd956832.pnj' 
  },
  {
    idDoze: 26, 
    nameDoze: 'REPELENTE REPELEX SPRAY 100ML 20%D', 
    imageUrlDoze: 'https://64.media.tumblr.com/3aa78c6df87e3b6eaf5679c77de8ed1a/c4bad9a5e5c70b97-4e/s250x400/aa3c12eb39066105cc47121f8c04d1c8595403f9.pnj' 
  },
  {
    idDoze: 27, 
    nameDoze: 'RESERVATORIO PLAST. 800ML. C/TAMPA (P/SABONETE LIQUIDO)', 
    imageUrlDoze: 'https://64.media.tumblr.com/087ef18e0fce7de7d5eb3830ee42c7be/c4bad9a5e5c70b97-65/s250x400/cef12ac03ede4f61b13daa5909fd1e184ae41503.pnj' 
  },
  {
    idDoze: 28, 
    nameDoze: 'TOALHA PAPEL SCALA PLUS 60FLS', 
    imageUrlDoze: 'https://64.media.tumblr.com/cc463b353f316dd3a7e29738746c0d0e/c4bad9a5e5c70b97-72/s250x400/cfe80c4824b8405c6d9172f2d185dc56a79750c9.pnj' 
  },
  {
    idDoze: 29, 
    nameDoze: 'SUPORTE PARA PENDURAR RODO E VASSOURA', 
    imageUrlDoze: 'https://64.media.tumblr.com/3236e58b82266daec0a24e4653874352/c4bad9a5e5c70b97-b8/s400x600/00e1714b64753f136de128d1c59f428c4e8688e2.pnj' 
  },
  {
    idDoze: 30, 
    nameDoze: 'VASELINA LIQ KING 500ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/d78f72fc8a5c2040a4f5c47797ceb161/c4bad9a5e5c70b97-04/s250x400/c3c826a3f173d1187999d625b985b292a2a776a1.pnj' 
  },
  {
    idDoze: 31, 
    nameDoze: 'VASELINA LIQ. WORKER 500ML', 
    imageUrlDoze: 'https://64.media.tumblr.com/f2d230aef50f844c1d36ebf7a4de5e91/923a3b406c56f292-7e/s250x400/96acddbb0e45921b8cb262dfa2abe146780d8816.pnj' 
  },
]

export default mockDiversityProducts
