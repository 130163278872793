import "./Products.scss";
import { BsBuildingsFill } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa6";
import { PiHandCoinsFill } from "react-icons/pi";

const Products = () => {
  return (
    <article id="Products" className="products-container">
      <div className="products-container__title">
        <p className="products-container__title-strong">Conheça um pouco mais sobre a LimpEletro</p>
      </div>

      <div className="products-container__content">
        <div className="products-container__our-products-align">
          <p className="products-container__title-strong">Missão</p>
          <p className="products-container__subtitle">
            Oferecer produtos de limpeza e materiais elétricos de alta qualidade a condomínios, 
            empresas, hospitais e clínicas, com o menor custo e prazo de entrega, adotando 
            práticas de governança que garantem o menor impacto possível para o meio ambiente.
          </p>
        </div>

        <div className="products-container__our-products-align">
          <p className="products-container__title-strong">Visão</p>
          <p className="products-container__subtitle">
          Ser reconhecida como a empresa líder em resolver os problemas dos clientes, 
          oferecendo soluções inovadoras e personalizadas que garantem o melhor custo x benefício do mercado.
          </p>
        </div>

        <div className="products-container__our-products-align">
          <p className="products-container__title-strong">Valores</p>
          <p className="products-container__subtitle">
            <ul>
              <li>A Satisfação dos nossos clientes em primeiro lugar</li>
              <li>O melhor custo x benefício para nossos clientes</li>
              <li>Ética nas nossas negociações</li>
              <li>Eficiência nas nossas entregas</li>
              <li>Comprometimento ambiental</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="products-container__content">
        <div className="products-container__our-products-align">
          <div className="products-container__bg-icon">
            <FaRegHandshake size={50} color="#408F96" />
          </div>
          <p className="products-container__title-strong">Cliente em primeiro lugar</p>
          <p className="products-container__subtitle">
            Na LimpEletro, nossos clientes são a essência da nossa existência.
            Estamos comprometidos em oferecer soluções personalizadas que atendam
            às suas necessidades de forma eficaz e eficiente.
          </p>
        </div>

        <div className="products-container__our-products-align">
          <div className="products-container__bg-icon">
            <BsBuildingsFill size={50} color="#408F96" />
          </div>
          <p className="products-container__title-strong">Para sua Empresa ou Condomínio</p>
          <p className="products-container__subtitle">
            A LimpEletro disponibiliza uma ampla gama de produtos de limpeza e
            materiais elétricos de alta qualidade, sempre com os melhores preços
            e prazos de entrega. Nosso objetivo é garantir a sua satisfação em cada compra.
          </p>
        </div>

        <div className="products-container__our-products-align">
          <div className="products-container__bg-icon">
            <PiHandCoinsFill size={50} color="#408F96" />
          </div>
          <p className="products-container__title-strong">Economize com a LimpEletro</p>
          <p className="products-container__subtitle">
            Faça seu pedido pelo nosso site e aproveite a entrega rápida e gratuita em toda a cidade do Rio de Janeiro.
            Experimente nossos serviços e comprove que somos a sua melhor escolha.
          </p>
        </div>
      </div>
    </article>
  );
};

export default Products;