import "./Section.scss";

const Section = () => {
  return (
    <section id="Section" className="section-container">
      <h1 className="section-container__h1">Segmentos de Atuação</h1>

      <div className="test">
        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/9cfa13d9e4a6318d4e2459c6b3a34b7b/c70d392f8f11ab14-3b/s540x810/ebf0c069bceb7a277db8bb5f0c7bb649b034bc0e.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Condomínios</p>
          </div>
        </div>

        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/d660260ebb50347b28edce104bade476/03ad296b71daa370-3d/s2048x3072/3811661b474b3272a0fac9d74eb585280d888359.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Hospitais</p>
          </div>
        </div>

        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/cd262260b6ddea0245f0057b7e976252/5c4d84b0bc14302d-a3/s2048x3072/d813cfc892be88435fbe7f54b83c1bf40329f8f4.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Empresas</p>
          </div>
        </div>

        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/a22a5f9002fa6097c1fd8b09e6ade6d2/d1381d6c2747926e-f5/s2048x3072/f3b90ce8a254c5fba05c80ba3994021232d55b26.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Clínicas</p>
          </div>
        </div>

        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/3e41d7b1b23bd523b3f92664f7860f9d/8905bd93a15af41f-b1/s2048x3072/74beba2ed0f75429b924ed62427fc5f1d052b6c1.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Escritórios</p>
          </div>
        </div>

        <div className="section-container__card-container">
          <div className="section-container__card-content">
            <img 
              className="section-container__img-card"
              src="https://64.media.tumblr.com/e07923aaa0eb5c520087e29d610b9b99/201a1af6f23b3387-d9/s2048x3072/b9a04e0947486bd72fd45456b495fc2f09cf4047.jpg" 
              alt="Imagens dos Segmentos" 
            />
            <p className="section-container__title-card">Restaurantes</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;