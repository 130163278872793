import ButtonContact from "../ButtonContact/ButtonContact";
import EmailField from "../EmailField/EmailField";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
            <div className="footer-container__social-medias">
        <p className="footer-container__title">Mande um Whatsapp</p>
        <ButtonContact />
      </div>

      <div>
        <EmailField />
      </div>
    </footer>
  );
};

export default Footer;